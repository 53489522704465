@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//page-news
.page-news {
    @media screen and (min-width: $tab) {
        overflow-x: auto;
    }
}

.page-news__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin: rem(32) auto 0;
    @media screen and (min-width: $tab) {
        max-width: 100%;
        width: 1050px;
        padding-inline: 25px;
        margin: rem(44) auto 0;
        overflow-y: hidden;
        overflow-x: auto;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
}

/*スクロールバー非表示（Chrome・Safari）*/
.page-news__inner::-webkit-scrollbar {
    display: none;
}

.page-news__contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: $tab) {
        flex-direction: row;
        align-items: flex-end;
        width: 1000px;
    }
}

.page-news__section-title {
    max-width: rem(168);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(264);
    }
}

.page-news__article-content {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin: rem(60) auto rem(81);
    @media screen and (min-width: $tab) {
        max-width: 100%;
        width: 1050px;
        padding-inline: 25px;
        margin: rem(73) auto rem(100);
        overflow-x: auto;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
}

/*スクロールバー非表示（Chrome・Safari）*/
.page-news__article-content::-webkit-scrollbar {
    display: none;
}

.page-news__article {
    cursor: pointer;
    transition: 0.5s ease;
    @media screen and (min-width: $tab) {
        width: 1000px;
        display: grid;
        grid-template-columns: 1fr 3.4536fr;
        grid-gap: 0px rem(29);
        align-items: center;
        padding: rem(26) rem(66) rem(26) rem(41);
        margin-bottom: rem(48);
    }
}

.page-news__article:hover {
    background-color: #f7f7f7;
    opacity: initial;
}

.page-news__article-img-wrap {
    margin-top: rem(60);
    width: 194px;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: 0;
    }
}

.page-news__article-img-wrap img {
    width: 100%;
    aspect-ratio: 194 / 110;
    object-fit: cover;
}

.page-news__article-info {
    margin-top: rem(23);
    font-size: rem(14);
    line-height: 1;
    color: $black;
    display: inline-flex;
    gap: rem(20);
    flex-wrap: wrap;
    @media screen and (min-width: $tab) {
        flex-direction: column;
        font-size: rem(16);
        gap: rem(12);
        margin-top: 0;
    }
}

.page-news__article-tag {
    font-family: $roboto;
    font-weight: bold;
    font-size: 11px;
    line-height: 1;
    padding: 2px 15px;
    border: 1px solid #707070;
    @media screen and (min-width: $tab) {
        font-size: 13px;
    }
}

.page-news__article-title {
    margin-top: rem(15);
    font-weight: 600;
    font-size: rem(16);
    line-height: calc(24 / 16);
    color: $sub-black1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    @media screen and (min-width: $tab) {
        margin-top: rem(12);
        font-size: rem(22);
        line-height: calc(35 / 22);
        -webkit-line-clamp: 3;
        width: 670px;
    }
}
