@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//news
.news__bg {
    max-width: rem(670);
    width: 100%;
    border-radius: 20px;
    padding-inline: rem(22);
    margin-inline: auto;
    background: linear-gradient(#f4f4f4 0%, #d3d3d3 100%);
    @media screen and (min-width: $tab) {
        max-width: rem(1128);
        padding: 0 rem(50) 0 rem(53);
    }
}

.news__contents {
    padding: rem(71) 0 rem(35);
    @media screen and (min-width: $tab) {
        padding: 57px 0 28px;
    }
}

.news__section-title-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.news__section-title {
    max-width: rem(168);
    width: 100%;
}

.news__button-text {
    font-family: $roboto;
    font-weight: bold;
    font-size: rem(20);
    line-height: 1;
    padding-right: rem(14);
    @media screen and (min-width: $tab) {
        font-size: rem(31);
    }
}

.news__button__arrow {
    border: 1px solid $txt-color;
    width: 22px;
    height: 22px;
    @media screen and (min-width: $tab) {
        width: 33px;
        height: 33px;
    }
}

//ホバー矢印
.news__button:hover .button__arrow {
    transform: translateX(8px);
}

.news__button__arrow.button__arrow.arrow::before {
    background: $txt-color;
}

.news__button__arrow.button__arrow.arrow::after {
    border-top: 1px solid $txt-color;
    border-right: 1px solid $txt-color;
}

//newsホバー画像拡大
.news__article1-img,
.news__article2-img,
.news__article3-img,
.news__article4-img {
    overflow: hidden;
}

.news__article1-img img,
.news__article2-img img,
.news__article3-img img,
.news__article4-img img {
    transition: transform 0.5s ease;
}

.news__link:hover .news__article1-img img,
.news__link:hover .news__article2-img img,
.news__link:hover .news__article3-img img,
.news__link:hover .news__article4-img img {
    transform: scale(1.1);
}

.news__article-wrap1 {
    margin-top: rem(33);
    @media screen and (min-width: $tab) {
        margin-top: rem(37);
    }
}

.news__content1 {
    max-width: rem(584);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: $tab) {
        max-width: rem(1110);
        flex-direction: row;
    }
}

.news__content-left {
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(500);
        padding-right: rem(40);
    }
}

.news__category {
    display: inline;
    font-weight: bold;
    font-size: 14px;
    line-height: calc(40 / 15);
    padding: 0 20px;
    border: 1px solid $txt-color;
    @media screen and (min-width: $tab) {
        font-size: 15px;
    }
}

.news__title1 {
    margin-top: 9px;
    font-weight: bold;
    font-size: rem(33);
    line-height: 1;
    @media screen and (min-width: $tab) {
        margin-top: 0;
        font-size: rem(44);
    }
    @media screen and (min-width: 1000px) {
        font-size: rem(55);
    }
}

.news__text1 {
    margin-top: rem(9);
    font-size: 13px;
    @media screen and (min-width: $tab) {
        margin-top: rem(15);
        font-size: rem(16);
    }
}

.news__article1-img {
    width: 100%;
    height: 100%;
    margin-top: rem(13);
    @media screen and (min-width: $tab) {
        margin-top: 0;
        max-width: rem(568);
    }
}

.news__article1-img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 568 / 276;
}

.news__article-wrap2 {
    margin-top: rem(20);
    max-width: rem(626);
    padding-inline: rem(22);
    width: 100%;
    display: grid;
    row-gap: rem(22);
    grid-template-columns: 1fr;
    @media screen and (min-width: $tab) {
        max-width: rem(1110);
        margin-top: rem(61);
        grid-template-columns: repeat(3, 1fr);
        gap: rem(35);
    }
}

.news__article2,
.news__article3,
.news__article4 {
    width: 100%;
}

.news__title2,
.news__title3,
.news__title4 {
    margin-top: 3px;
    font-weight: bold;
    font-size: rem(18);
    line-height: calc(20 / 18);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.news__text2,
.news__text3,
.news__text4 {
    margin-top: rem(3);
    font-size: 13px;
    line-height: calc(26 / 16);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media screen and (min-width: $tab) {
        font-size: rem(16);
    }
}

.news__article2-img,
.news__article3-img,
.news__article4-img {
    margin-top: rem(13);
    width: 100%;
    @media screen and (min-width: $tab) {
        margin-top: rem(6);
    }
}

.news__article2-img img,
.news__article3-img img,
.news__article4-img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 234 / 132;
}
