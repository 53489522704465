@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//arrow
.arrow{
	position: relative;
	display: inline-block;
	padding: 0 0 0 16px;
	color: $white;
	vertical-align: middle;
	text-decoration: none;
	font-size: 15px;
}
.arrow::before,
.arrow::after{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	content: "";
	vertical-align: middle;
}

.arrow::before{
	left: 5px;
	width: 11px;
	height: 1px;
	background: $white;
	@media screen and (min-width: $tab) {
		left: 4px;
		width: 25px;
    }
}
.arrow::after{
	left: 10px;
	width: 4px;
	height: 4px;
	border-top: 1px solid $white;
	border-right: 1px solid $white;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	@media screen and (min-width: $tab) {
		left: 18px;
		width: 8px;
		height: 8px;
    }
}



