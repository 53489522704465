@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//media
.media__section-title {
    width: 100%;
    max-width: rem(98);
    @media screen and (min-width: $tab) {
        max-width: 122px;
    }
}
.media__banner-wrap {
    margin-top: rem(15);
    margin-inline: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #bfbfbf;
    padding: rem(14) rem(26);
    @media screen and (min-width: $tab) {
        max-width: 1152px;
        width: 100%;
        flex-direction: row;
        border: none;
        padding: 0;
    }
}

.media__banner {
    width: 100%;
    @media screen and (min-width: $tab) {
        width: 568px;
        min-height: 228px;
        border-radius: 20px;
        border: 1px solid #bfbfbf;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.media__banner-img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.media__banner-img img {
    width: 100%;
    height: 100%;
}

.media__banner1 {
    @media screen and (min-width: $tab) {
        margin-right: rem(16);
    }
}

.media__banner2 {
    margin: rem(20) rem(20) 0 0;
    @media screen and (min-width: 1203px) {
        margin: 0;
    }
}
.media__banner3 {
    @media screen and (min-width: $tab) {
        margin-top: 15px;
    }
}

.media__banner-img1 {
    max-width: rem(276);
    width: 100%;
    border-bottom: 1px solid #707070;
    padding: 0 0 23px 0;
    @media screen and (min-width: $tab) {
        max-width: 396px;
        border-bottom: none;
        padding: 0;
    }
}

.media__banner-img2 {
    max-width: rem(280);
    width: 100%;
    border-bottom: 1px solid #707070;
    padding: 32px 0 32px 0;
    @media screen and (min-width: $tab) {
        max-width: 492px;
        border-bottom: none;
        padding: 0;
    }
}

.media__banner-img3 {
    max-width: rem(268);
    width: 100%;
    padding: 16px 0 0 0;
    @media screen and (min-width: $tab) {
        max-width: 396px;
        border-bottom: none;
        padding: 0;
    }
}
