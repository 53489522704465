@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//button
.button {
	display: inline-block;
	display: flex;
	align-items: center;
}

.button__arrow {
	background-color: transparent;
	border-radius: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.5s ease;
}

.button-text:hover .button__arrow {
	transform: translateX(8px);
}
