@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//page-philosophy
.page-philosophy {
    @media screen and (min-width: $tab) {
        overflow-x: auto;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
}
/*スクロールバー非表示（Chrome・Safari）*/
.page-philosophy::-webkit-scrollbar {
    display: none;
}
.page-philosophy__bg {
    background-image: url("../images/page-philosophy/page-philosophy-bg.webp");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding: rem(115) 0 rem(155);
    @media screen and (min-width: $tab) {
        width: 100%;
        width: fit-content;
        padding: rem(150) 0 rem(216);
    }
    @media screen and (min-width: 1058px) {
        width: 100%;
        padding: rem(150) 0 rem(216);
    }
}

.page-philosophy__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 1083px;
        width: 1083px;
        padding-inline: 25px;
    }
}

.page-philosophy__contents {
    color: $white;
    @media screen and (min-width: $tab) {
        width: 1033px;
        overflow-y: hidden;
        overflow-x: auto;
    }
}

.page-philosophy__en {
    max-width: rem(295);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 446px;
    }
}

.page-philosophy__en img {
    width: 100%;
    height: 100%;
}

.page-philosophy__ja {
    font-size: rem(21);
    line-height: 1;
    @media screen and (min-width: $tab) {
        font-size: 34px;
    }
}

.page-philosophy__title {
    margin-top: rem(115);
    font-weight: 900;
    font-size: rem(42);
    letter-spacing: 0.1em;
    line-height: calc(48 / 42);
    @media screen and (min-width: $tab) {
        margin-top: rem(105);
        font-size: 92px;
        line-height: calc(101 / 92);
    }
}

.page-philosophy__text {
    margin-top: rem(71);
    font-weight: 900;
    font-size: rem(28);
    line-height: calc(40 / 28);
    @media screen and (min-width: $tab) {
        margin-top: rem(148);
        font-size: 50px;
        line-height: calc(70 / 50);
    }
}

.page-philosophy__text2,
.page-philosophy__text3 {
    margin-top: rem(51);
    @media screen and (min-width: $tab) {
        margin-top: rem(90);
    }
}

.page-philosophy__section-title--common {
    position: relative;
    display: block;
    text-align: center;
    @media screen and (min-width: $tab) {
        width: 1152px;
    }
}
.page-philosophy__section-title--common::after {
    position: absolute;
    display: block;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: 100%;
    height: 3px;
    background-color: $blue;
}
