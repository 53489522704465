@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.drawer__menu-lists {
	display: none;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	overflow-y: scroll;
}

.drawer__menu-content {
	padding-bottom: 96px;
	@media screen and (min-width:$tab) {
		padding-bottom: 73px;
	}
}

.drawer__footer {
	display: none;
	width: 100vw;
	height: 91px;
	position: fixed;
	bottom: 0;
	left: 0;
	@media screen and (min-width:$tab) {
		height: 73px;
	}
}