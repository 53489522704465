@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//pickUp

.pickUp__inner {
    display: flex;
    justify-content: center;
}

.pickUp__contents {
    max-width: 1152px;
    width: 100%;
}

.pickUp__section-title {
    max-width: rem(264);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 459px;
    }
}

.pickUp__section-title img {
    width: 100%;
    height: 100%;
}

.pickUp__section-text {
    font-size: rem(20);
    line-height: calc(22 / 20);
    font-weight: 900;
    color: $black;
    @media screen and (min-width: $tab) {
        margin-top: rem(11);
        font-size: 50px;
        line-height: calc(55 / 50);
    }
}

.pickUp__article-wrap {
    margin-top: rem(15);
    max-width: rem(710);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    @media screen and (min-width: $tab) {
        margin-top: 19px;
        grid-template-columns: 1fr 1fr;
        max-width: 1152px;
        gap: 21px;
    }
}
.pickUp__article {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(#f4f4f4 0%, #d3d3d3 100%);
    padding-inline: rem(20);
}

.pickUp__article1 {
    max-width: rem(670);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 565px;
        grid-column: 1/2;
        grid-row: 1/3;
    }
}

.pickUp__article2 {
    max-width: rem(670);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 565px;
        grid-column: 2/3;
        grid-row: 1/2;
    }
}

.pickUp__article3 {
    max-width: rem(670);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 565px;
        grid-column: 2/3;
        grid-row: 2/3;
    }
}

.pickUp__article4 {
    max-width: rem(670);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 1152px;
        grid-column: 1/3;
        grid-row: 3/4;
    }
}

.pickUp__article5 {
    max-width: rem(670);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 565px;
        grid-column: 1/2;
        grid-row: 4/5;
    }
}

.pickUp__article6 {
    max-width: rem(670);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 565px;
        grid-column: 2/3;
        grid-row: 4/5;
    }
}

.pickUp__content {
    display: flex;
}

.pickUp__content1 {
    flex-direction: column;
    max-width: rem(448);
    width: 100%;
    margin-inline: auto;
    padding: rem(41) 0 rem(63);
    @media screen and (min-width: $tab) {
        max-width: 304px;
        padding: rem(79) 0 rem(90);
    }
}

.pickUp__content2 {
    max-width: rem(477);
    width: 100%;
    margin-inline: auto;
    padding: rem(24) 0 rem(40);
    align-items: center;
    @media screen and (min-width: $tab) {
        max-width: 477px;
        padding: rem(55) 0 rem(48);
    }
}

.pickUp__content3 {
    max-width: rem(477);
    width: 100%;
    margin-inline: auto;
    padding: rem(24) 0 rem(40);
    align-items: center;
    @media screen and (min-width: $tab) {
        max-width: 477px;
        padding: rem(51) 0 rem(54);
    }
}

.pickUp__content4 {
    flex-direction: column;
    max-width: rem(224);
    width: 100%;
    margin-inline: auto;
    padding: rem(19) 0 rem(15);
    @media screen and (min-width: $tab) {
        flex-direction: row;
        align-items: center;
        max-width: 920px;
        padding: rem(41) 0 rem(36);
    }
}

.pickUp__content5,
.pickUp__content6 {
    max-width: rem(477);
    width: 100%;
    margin-inline: auto;
    padding: rem(24) 0 rem(41);
    align-items: center;
    @media screen and (min-width: $tab) {
        max-width: 477px;
        padding: rem(44) 0 rem(54);
    }
}

.pickUp__category {
    margin: 20px 0 0 20px;
    display: inline-block;
    border-radius: 14px;
    background-color: $blue;
    padding: 0 20px;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.5;
    color: $white;
}

.pickUp__img {
    text-align: center;
    flex-shrink: 0;
}

.pickUp__img img {
    width: 100%;
    height: 100%;
}

.pickUp__article1-img {
    max-width: rem(224);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 292px;
    }
}

.pickUp__article2-img,
.pickUp__article3-img,
.pickUp__article5-img,
.pickUp__article6-img  {
    max-width: rem(131);
    width: 100%;
    margin-right: rem(20);
    @media screen and (min-width: $tab) {
        max-width: 155px;
        width: 100%;
        margin-right: 2.8125vw;
    }
}

.pickUp__article4-img {
    max-width: rem(449);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 181px;
        width: 100%;
        margin-right: rem(51);
    }
}

.pickUp__title {
    margin-top: rem(22);
    font-weight: bold;
    font-size: rem(18);
    line-height: calc(24 / 18);
    @media screen and (min-width: $tab) {
        margin-top: 15px;
        font-size: 26px;
        line-height: calc(30 / 26);
    }
}

.pickUp__text {
    margin-top: rem(11);
    font-weight: 500;
    font-size: 14px;
    line-height: calc(19 / 14);
    color: #404040;
    @media screen and (min-width: $tab) {
        margin-top: 21px;
        font-size: 16px;
        line-height: calc(25 / 16);
    }
}

//pickUpホバー画像拡大
.pickUp__img {
    overflow: hidden;
}

.pickUp__img img {
    transition: transform 0.5s ease;
}

.pickUp__link:hover .pickUp__article1-img img,
.pickUp__link:hover .pickUp__article2-img img,
.pickUp__link:hover .pickUp__article3-img img,
.pickUp__link:hover .pickUp__article4-img img,
.pickUp__link:hover .pickUp__article5-img img,
.pickUp__link:hover .pickUp__article6-img img {
    transform: scale(1.1);
}

