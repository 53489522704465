@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//section-title
.section-title__contents {
    width: 100%;
    padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        padding-inline: 25px;
    }
    @media screen and (min-width: 1150px){
        max-width: 1202px;
        display: flex;
        align-items: center;
    }
}

.section-title__title {
    font-weight: bold;
    font-size: rem(28);
    color: $section-color;
    @media screen and (min-width: 1150px) {
        font-size: 44px;
        padding-right: 2.1093vw;
        order: 0;
    }
}

.section-title__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 1150px) {
        display: contents;
        padding-right: 8.3593vw;
}
}

.section-title__text {
    margin-top: 5px;
    font-size: 14px;
    line-height: calc(20 / 14);
    @media screen and (min-width: 1150px) {
        margin-top: 0;
        font-size: 20px;
        order: 1;
    }
}

