@charset "UTF-8";

body {
  font-family: "Noto Sans JP", sans-serif;
}

/* pcの電話番号発信対応 */

/* ホバー */

a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
  cursor: pointer;
}

a:hover {
  opacity: 0.8;
}

/* ハンバーガーメニュークリックで背景固定 */

body {
  overflow-y: auto;
}

body.is-fixed {
  overflow-y: hidden;
}

/* スクロール中にヘッダーを消す */

.hide {
  transform: translateY(-100%);
  /* 上に消えるようにする */
}

#scrollArea {
  position: fixed;
  /* 固定して表示されるようにする */
  top: 0;
  /* 表示場所を一番上に */
  transition: 0.5s;
  /* スクロール時に一瞬で消えると物足りないので.hideの処理を0.5秒で行う */
  z-index: 100;
  /* 前面に表示されるようにする */
}

/* base-color */

body {
  color: #1C1C1C;
}

.pc-br {
  display: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Set core root defaults */

html {
  scroll-behavior: smooth;
}

/* Set core body defaults */

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */

ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Inherit fonts for inputs and buttons */

textarea,
select {
  font: inherit;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon__flame {
  position: relative;
  width: 10px;
  height: 10px;
  border: 1px solid black;
}

.icon__background {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background-color: white;
}

.icon__arrow {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.icon__arrow .icon__bar:nth-child(1) {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 1px;
  background-color: #1C1C1C;
  transform: rotate(-45deg);
}

.icon__arrow .icon__bar:nth-child(2) {
  position: absolute;
  top: 5%;
  right: 0;
  width: 6px;
  height: 1px;
  background-color: #1C1C1C;
}

.icon__arrow .icon__bar:nth-child(3) {
  position: absolute;
  top: 5%;
  right: 0;
  width: 1px;
  height: 6px;
  background-color: #1C1C1C;
}

.works__icon-wrap:hover .icon__arrow {
  /* 右ななめ上 */
  transform: translate(2px, -2px);
}

.seminer__icon-wrap:hover .icon__arrow {
  /* 右ななめ上 */
  transform: translate(2px, -2px);
}

.icon__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 2.5714285714;
  display: inline-flex;
}

.inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

.header {
  background-color: white;
  position: fixed;
  width: 100%;
  height: 57px;
  z-index: 999;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  height: inherit;
}

.header__logo img {
  max-width: 7.9375rem;
  width: 100%;
}

.drawer__menu-lists {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.drawer__menu-content {
  padding-bottom: 96px;
}

.drawer__footer {
  display: none;
  width: 100vw;
  height: 91px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.hamburger {
  position: relative;
}

.hamburger__btn {
  width: 100%;
  display: block;
  width: 39px;
  height: 39px;
  position: absolute;
  right: 0;
  z-index: 999;
  /*追記：いつも一番上*/
  cursor: pointer;
}

button.hamburger__btn {
  border: none;
  outline: none;
  background: transparent;
}

.bar {
  width: 39px;
  height: 2px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #7E7E7E;
}

.bar-top {
  top: 10px;
}

.bar-middle {
  top: 50%;
  transform: translate(-50%, -50%);
}

.bar-bottom {
  bottom: 10px;
}

.hamburger__btn.close .bar-top {
  transform: translate(-50%, 10px) rotate(45deg);
  transition: transform 0.3s;
  background-color: white;
}

.hamburger__btn.close .bar-middle {
  opacity: 0;
  transition: opacity 0.3s;
  background-color: white;
}

.hamburger__btn.close .bar-bottom {
  transform: translate(-50%, -8px) rotate(-45deg);
  transition: transform 0.3s;
  background-color: white;
}

.menu-lists {
  padding: 2.875rem 0 0.375rem;
  background: transparent linear-gradient(179deg, #0097e5 0%, #003c5a 100%) 0% 0% no-repeat padding-box;
}

.menu-lists__inner {
  max-width: 68.125rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.menu-list {
  display: flex;
  flex-direction: column;
  color: white;
}

.menu-list__service {
  max-width: 20rem;
  width: 100%;
}

.menu-list__title img {
  width: 100%;
  height: 100%;
}

.menu-list__title--service {
  max-width: 6rem;
  width: 100%;
}

.menu-list__title--news {
  max-width: 4.1875rem;
  width: 100%;
}

.menu-title__en-powerOfEditing img {
  max-width: 13.375rem;
  width: 100%;
}

.menu-title__en-publication img {
  max-width: 9.25rem;
  width: 100%;
}

.menu-title__en-seminar img {
  max-width: 6.5rem;
  width: 100%;
}

.menu-title__en-company img {
  max-width: 6.9375rem;
  width: 100%;
}

.menu-title__en-philosophy img {
  max-width: 9rem;
  width: 100%;
}

.menu-title__en-recruit img {
  max-width: 6rem;
  width: 100%;
}

.menu-list__service > ul,
.menu-list__news > ul {
  margin-top: 11px;
}

.menu-list__service > ul > li,
.menu-list__news > ul > li {
  font-weight: bold;
  font-size: 12px;
}

.menu-list__news-lists > ul > li {
  font-weight: bold;
  font-size: 12px;
}

.menu-list__service > ul > li {
  margin-top: 15px;
}

.menu-list__news > ul > li {
  margin-top: 8px;
}

.menu-list__news {
  margin-top: 47px;
  max-width: 18.5rem;
  width: 100%;
}

.menu-list__news-lists {
  display: flex;
}

ul.menu-list__news-list:nth-child(1) {
  padding-right: 2.6875rem;
}

.menu-list__other {
  margin-top: 47px;
  width: 100%;
}

.menu-list__other-content {
  max-width: 19.6875rem;
  width: 100%;
}

.menu-list__menu-title {
  margin-top: 20px;
}

.menu-list__other-content > a:nth-child(1) > div {
  margin-top: 0px;
}

.menu-list__arrow {
  transition: transform 0.5s ease;
}

.menu-list__arrow.arrow {
  position: relative;
}

.menu-list__arrow.arrow::before {
  left: 4px;
  width: 25px;
}

.menu-list__arrow.arrow::after {
  left: 18px;
  width: 8px;
  height: 8px;
}

.menu-title__en {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-title__ja {
  margin-top: -5px;
  font-size: 12px;
  line-height: 1;
}

.menu-list__menu-title:hover .menu-list__arrow {
  transform: translateX(8px);
}

.menu-info {
  margin-top: 46px;
  text-align: right;
}

.menu-info__search {
  height: 19px;
  display: flex;
  justify-content: flex-end;
}

#form {
  position: relative;
  max-width: 151px;
  width: 100%;
  height: inherit;
  border-radius: 10px;
  background-color: transparent;
  padding-right: 18px;
}

#sbox {
  height: inherit;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
  border: 1px solid #fff;
  outline: 0;
  background-color: transparent;
  color: white;
  width: 100%;
}

#sbox::placeholder {
  color: white;
}

button#sbtn {
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 10px;
  height: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#sbtn::before {
  content: "";
  width: 13px;
  height: 13px;
  background-image: url("../images/search-icon.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  z-index: 10;
}

.menu-info__search-icon:hover {
  color: #7fbfff;
}

.menu-info__contact {
  margin-top: 16px;
  font-size: 13px;
  line-height: 0.65;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-info__contact-icon {
  max-width: 21px;
  width: 100%;
  margin-right: 5px;
}

.menu-info__sns {
  margin-top: 13px;
  display: flex;
  justify-content: flex-end;
}

a.menu-info__sns-icon {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 13px;
  line-height: 0.3939393939;
}

.menu-info__sns > div:nth-child(1) {
  padding-right: 2.25rem;
}

.menu-info__sns > div:nth-child(2) {
  padding-right: 1.1875rem;
}

.menu-info__tel {
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  line-height: 1;
  white-space: nowrap;
}

.menu-info__tel > a:nth-child(1) {
  margin-right: 1rem;
}

.menu-info__copyright {
  margin-top: 11px;
  font-size: 11px;
  line-height: 1;
}

.footer-logo {
  background: #ddd;
  padding: 22px 0;
}

.footer-logo__inner {
  max-width: 66.25rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.footer-logo__items {
  display: flex;
}

.footer-logo__item-wrap {
  display: flex;
  align-items: center;
}

.footer-logo__item {
  display: inline-block;
  margin-right: 13.0625rem;
}

.footer-logo__item.footer-logo__item--last {
  margin-right: 0;
}

.button {
  display: inline-block;
  display: flex;
  align-items: center;
}

.button__arrow {
  background-color: transparent;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
}

.button-text:hover .button__arrow {
  transform: translateX(8px);
}

.arrow {
  position: relative;
  display: inline-block;
  padding: 0 0 0 16px;
  color: white;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.arrow::before,
.arrow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.arrow::before {
  left: 5px;
  width: 11px;
  height: 1px;
  background: white;
}

.arrow::after {
  left: 10px;
  width: 4px;
  height: 4px;
  border-top: 1px solid white;
  border-right: 1px solid white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header-spacer {
  height: 57px;
}

.js-fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: opacity 0.5s, visibility 0.5s, transform 1s;
}

.scroll {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.area {
  width: 100vw;
  overflow: hidden;
}

.wrap {
  position: relative;
  height: 100vh;
}

.loading__title {
  opacity: 0;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  will-change: opacity;
}

.loading__title img {
  max-width: 20.4375rem;
  width: 100%;
  height: 80%;
}

.mv__bg {
  opacity: 0;
  margin-top: -230%;
  width: 100%;
  background-image: url("../images/top/mv-bg.webp");
  background-size: cover;
  background-position: top;
  z-index: 5;
  padding: 16.5625rem 0 2.625rem;
}

.mv__inner {
  max-width: 44.125rem;
  width: 100%;
  padding: 0 1.25rem;
  margin-inline: auto;
}

.mv__title {
  padding-top: 14.6875rem;
  font-size: 3.5rem;
  width: 100%;
  text-align: center;
  font-weight: 900;
  line-height: 1;
  color: white;
  z-index: 10;
  border: 1px solid rgba(0, 0, 0, 0);
}

.mv__lead-content {
  margin-top: 17.625rem;
  width: 100%;
}

.mv__lead {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  letter-spacing: 0.1em;
  color: #fff;
}

.mv__text1 {
  font-size: 2.75rem;
  line-height: 1.0909090909;
  font-weight: 900;
}

.mv__text2 {
  margin-top: 0.625rem;
  font-size: 1.5rem;
  line-height: 1.3333333333;
  max-width: 41.625rem;
  width: 100%;
  font-weight: 800;
}

.mv__button-link {
  margin-top: 0.875rem;
  margin-left: auto;
}

.mv__button {
  text-align: right;
  display: inline-flex;
  justify-content: flex-end;
}

p.mv__button-text {
  font-size: 1rem;
  color: white;
  padding-right: 14px;
}

.mv__button__arrow {
  border: 1px solid white;
  width: 22px;
  height: 22px;
}

.mv__button:hover .mv__button__arrow {
  transform: translateX(8px);
}

.news__bg {
  max-width: 41.875rem;
  width: 100%;
  border-radius: 20px;
  padding-inline: 1.375rem;
  margin-inline: auto;
  background: linear-gradient(#f4f4f4 0%, #d3d3d3 100%);
}

.news__contents {
  padding: 4.4375rem 0 2.1875rem;
}

.news__section-title-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news__section-title {
  max-width: 10.5rem;
  width: 100%;
}

.news__button-text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1;
  padding-right: 0.875rem;
}

.news__button__arrow {
  border: 1px solid #1C1C1C;
  width: 22px;
  height: 22px;
}

.news__button:hover .button__arrow {
  transform: translateX(8px);
}

.news__button__arrow.button__arrow.arrow::before {
  background: #1C1C1C;
}

.news__button__arrow.button__arrow.arrow::after {
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
}

.news__article1-img,
.news__article2-img,
.news__article3-img,
.news__article4-img {
  overflow: hidden;
}

.news__article1-img img,
.news__article2-img img,
.news__article3-img img,
.news__article4-img img {
  transition: transform 0.5s ease;
}

.news__link:hover .news__article1-img img,
.news__link:hover .news__article2-img img,
.news__link:hover .news__article3-img img,
.news__link:hover .news__article4-img img {
  transform: scale(1.1);
}

.news__article-wrap1 {
  margin-top: 2.0625rem;
}

.news__content1 {
  max-width: 36.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news__content-left {
  width: 100%;
}

.news__category {
  display: inline;
  font-weight: bold;
  font-size: 14px;
  line-height: 2.6666666667;
  padding: 0 20px;
  border: 1px solid #1C1C1C;
}

.news__title1 {
  margin-top: 9px;
  font-weight: bold;
  font-size: 2.0625rem;
  line-height: 1;
}

.news__text1 {
  margin-top: 0.5625rem;
  font-size: 13px;
}

.news__article1-img {
  width: 100%;
  height: 100%;
  margin-top: 0.8125rem;
}

.news__article1-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 568/276;
}

.news__article-wrap2 {
  margin-top: 1.25rem;
  max-width: 39.125rem;
  padding-inline: 1.375rem;
  width: 100%;
  display: grid;
  row-gap: 1.375rem;
  grid-template-columns: 1fr;
}

.news__article2,
.news__article3,
.news__article4 {
  width: 100%;
}

.news__title2,
.news__title3,
.news__title4 {
  margin-top: 3px;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news__text2,
.news__text3,
.news__text4 {
  margin-top: 0.1875rem;
  font-size: 13px;
  line-height: 1.625;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news__article2-img,
.news__article3-img,
.news__article4-img {
  margin-top: 0.8125rem;
  width: 100%;
}

.news__article2-img img,
.news__article3-img img,
.news__article4-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 234/132;
}

.pickUp__inner {
  display: flex;
  justify-content: center;
}

.pickUp__contents {
  max-width: 1152px;
  width: 100%;
}

.pickUp__section-title {
  max-width: 16.5rem;
  width: 100%;
}

.pickUp__section-title img {
  width: 100%;
  height: 100%;
}

.pickUp__section-text {
  font-size: 1.25rem;
  line-height: 1.1;
  font-weight: 900;
  color: #000000;
}

.pickUp__article-wrap {
  margin-top: 0.9375rem;
  max-width: 44.375rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
}

.pickUp__article {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(#f4f4f4 0%, #d3d3d3 100%);
  padding-inline: 1.25rem;
}

.pickUp__article1 {
  max-width: 41.875rem;
  width: 100%;
}

.pickUp__article2 {
  max-width: 41.875rem;
  width: 100%;
}

.pickUp__article3 {
  max-width: 41.875rem;
  width: 100%;
}

.pickUp__article4 {
  max-width: 41.875rem;
  width: 100%;
}

.pickUp__article5 {
  max-width: 41.875rem;
  width: 100%;
}

.pickUp__article6 {
  max-width: 41.875rem;
  width: 100%;
}

.pickUp__content {
  display: flex;
}

.pickUp__content1 {
  flex-direction: column;
  max-width: 28rem;
  width: 100%;
  margin-inline: auto;
  padding: 2.5625rem 0 3.9375rem;
}

.pickUp__content2 {
  max-width: 29.8125rem;
  width: 100%;
  margin-inline: auto;
  padding: 1.5rem 0 2.5rem;
  align-items: center;
}

.pickUp__content3 {
  max-width: 29.8125rem;
  width: 100%;
  margin-inline: auto;
  padding: 1.5rem 0 2.5rem;
  align-items: center;
}

.pickUp__content4 {
  flex-direction: column;
  max-width: 14rem;
  width: 100%;
  margin-inline: auto;
  padding: 1.1875rem 0 0.9375rem;
}

.pickUp__content5,
.pickUp__content6 {
  max-width: 29.8125rem;
  width: 100%;
  margin-inline: auto;
  padding: 1.5rem 0 2.5625rem;
  align-items: center;
}

.pickUp__category {
  margin: 20px 0 0 20px;
  display: inline-block;
  border-radius: 14px;
  background-color: #0077B4;
  padding: 0 20px;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.5;
  color: white;
}

.pickUp__img {
  text-align: center;
  flex-shrink: 0;
}

.pickUp__img img {
  width: 100%;
  height: 100%;
}

.pickUp__article1-img {
  max-width: 14rem;
  width: 100%;
  margin-inline: auto;
}

.pickUp__article2-img,
.pickUp__article3-img,
.pickUp__article5-img,
.pickUp__article6-img {
  max-width: 8.1875rem;
  width: 100%;
  margin-right: 1.25rem;
}

.pickUp__article4-img {
  max-width: 28.0625rem;
  width: 100%;
}

.pickUp__title {
  margin-top: 1.375rem;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.3333333333;
}

.pickUp__text {
  margin-top: 0.6875rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3571428571;
  color: #404040;
}

.pickUp__img {
  overflow: hidden;
}

.pickUp__img img {
  transition: transform 0.5s ease;
}

.pickUp__link:hover .pickUp__article1-img img,
.pickUp__link:hover .pickUp__article2-img img,
.pickUp__link:hover .pickUp__article3-img img,
.pickUp__link:hover .pickUp__article4-img img,
.pickUp__link:hover .pickUp__article5-img img,
.pickUp__link:hover .pickUp__article6-img img {
  transform: scale(1.1);
}

.service__section-title {
  max-width: 20.9375rem;
  width: 100%;
}

.service__section-text-wrap {
  display: flex;
  align-items: flex-end;
}

.service__section-text {
  font-weight: 900;
  font-size: 1.75rem;
  line-height: 1.2666666667;
}

.service__button {
  display: none;
}

.service__button-text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  padding-right: 9px;
  display: inline-block;
}

.service__button__arrow {
  border: 1px solid #1C1C1C;
  width: 19px;
  height: 19px;
}

.service__button__arrow.button__arrow.arrow::before {
  left: 4px;
  width: 10px;
}

.service__button__arrow.button__arrow.arrow::after {
  left: 10px;
  width: 3px;
  height: 3px;
}

.service__button:hover .button__arrow {
  transform: translateX(8px);
}

.service__button__arrow.button__arrow.arrow::before {
  background: #1C1C1C;
}

.service__button__arrow.button__arrow.arrow::after {
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
}

.service__article-wrap {
  margin-top: 5rem;
  max-width: 44.375rem;
  margin-inline: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
}

.service__article {
  width: 100%;
  height: 100%;
}

.service__article1 {
  width: 100%;
  height: 100%;
}

.service__article2 {
  width: 100%;
  height: 100%;
}

.service__article3 {
  width: 100%;
  height: 100%;
}

.service__article4 {
  width: 100%;
  height: 100%;
}

.service__article5 {
  width: 100%;
  height: 100%;
}

.service__article-wrap2 {
  margin-top: 1.25rem;
  margin-inline: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.25rem;
  gap: 1.25rem;
}

.service__content {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
}

.service__article-img img {
  width: 100%;
  height: 100%;
}

.service__article-img::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.11);
}

.service__content3,
.service__content4,
.service__content5 {
  width: 100%;
}

.service__content3 img,
.service__content4 img,
.service__content5 img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 328/304;
}

.service__body {
  width: 100%;
  background-color: #e3e3e3;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 12px 23px 20px 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.service__body1,
.service__body2 {
  min-height: 130px;
}

.service__body3,
.service__body4,
.service__body5 {
  min-height: 130px;
}

.service__title {
  font-weight: 900;
  color: #2680eb;
}

.service__title1 {
  font-size: 1.625rem;
  line-height: 1.1538461538;
}

.service__title2 {
  font-size: 1.625rem;
  line-height: 1.1538461538;
}

.service__title3,
.service__title4,
.service__title5 {
  font-size: 1.3125rem;
  line-height: 1.1428571429;
}

.service__text {
  font-size: 13px;
  line-height: 1.3846153846;
  font-weight: 300;
}

.service__content {
  overflow: hidden;
}

.service__article-img {
  transition: transform 0.5s ease;
}

.service__link:hover .service__article1-img,
.service__link:hover .service__article2-img,
.service__link:hover .service__article3-img,
.service__link:hover .service__article4-img,
.service__link:hover .service__article5-img,
.service__link:hover .service__article6-img,
.service__link:hover .service__article7-img,
.service__link:hover .service__article8-img,
.service__link:hover .service__article9-img {
  transform: scale(1.1);
}

.works__icon-wrap {
  text-wrap: nowrap;
}

.works__inner {
  margin-right: 0;
  padding-right: 0;
  margin-left: auto;
  padding-left: 1.25rem;
}

.works__article-wrap {
  margin-top: 1.25rem;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /*スクロールバー非表示（Firefox）*/
  scrollbar-width: none;
  padding-bottom: 20px;
  margin-inline: auto;
}

/*スクロールバー非表示（Chrome・Safari）*/

.works__article-wrap::-webkit-scrollbar {
  display: none;
}

.works__card {
  background-image: url("../images/top/works-content-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin-right: 26px;
  width: 253px;
  position: relative;
}

.works__content {
  padding: 80px 0 54px;
}

.works__category {
  position: absolute;
  top: 6%;
  left: 3%;
  background: #6e08e6;
  padding-inline: 20px;
  z-index: 10;
}

.works__article-img {
  max-width: 173px;
  width: 100%;
  margin-inline: auto;
}

.works__article-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 173/250;
}

.works__content {
  overflow: hidden;
}

.works__article-img {
  transition: transform 0.5s ease;
}

.works__link:hover .works__article1-img,
.works__link:hover .works__article2-img,
.works__link:hover .works__article3-img,
.works__link:hover .works__article4-img,
.works__link:hover .works__article5-img {
  transform: scale(1.1);
}

.media__section-title {
  width: 100%;
  max-width: 6.125rem;
}

.media__banner-wrap {
  margin-top: 0.9375rem;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #bfbfbf;
  padding: 0.875rem 1.625rem;
}

.media__banner {
  width: 100%;
}

.media__banner-img {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.media__banner-img img {
  width: 100%;
  height: 100%;
}

.media__banner2 {
  margin: 1.25rem 1.25rem 0 0;
}

.media__banner-img1 {
  max-width: 17.25rem;
  width: 100%;
  border-bottom: 1px solid #707070;
  padding: 0 0 23px 0;
}

.media__banner-img2 {
  max-width: 17.5rem;
  width: 100%;
  border-bottom: 1px solid #707070;
  padding: 32px 0 32px 0;
}

.media__banner-img3 {
  max-width: 16.75rem;
  width: 100%;
  padding: 16px 0 0 0;
}

.project__section-title {
  max-width: 8.5rem;
}

.project__banner-bg {
  margin-top: 0.9375rem;
  max-width: 1152px;
  width: 100%;
  border-radius: 20px;
  background: #a1b2c6;
  margin-inline: auto;
}

.project__banner-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 2.5625rem;
  line-height: 1.1463414634;
  letter-spacing: 0.1em;
  line-height: 1;
  color: white;
  padding: 1rem 0;
}

.recruit__section-title {
  max-width: 8.125rem;
}

.recruit__banner {
  margin-top: 0.875rem;
}

.recruit__banner-bg {
  background-image: url("../images/top/recruit-img-sp.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 41.875rem;
  width: 100%;
  height: 15.625rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  margin-inline: auto;
}

.recruit__banner-title {
  font-size: 2.9375rem;
  line-height: 1.1489361702;
  font-weight: 900;
  letter-spacing: 0.1em;
  color: white;
  z-index: 2;
}

.seminer__inner {
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  /*スクロールバー非表示（Firefox）*/
  scrollbar-width: none;
}

/*スクロールバー非表示（Chrome・Safari）*/

.seminer__inner::-webkit-scrollbar {
  display: none;
}

.seminer__article-wrap {
  margin-top: 1.25rem;
  display: flex;
  flex-wrap: nowrap;
  width: 1192px;
  padding-inline: 1.25rem;
}

.seminer__article {
  position: relative;
  margin-right: 28px;
  width: 309px;
}

.seminer__article:nth-last-child(1) {
  margin-right: 0;
}

.seminer__link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 25%;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
  border-radius: 0 0 20px 20px;
}

.seminer__img {
  width: 309px;
  height: 100%;
}

.seminer__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 309/408;
}

.seminer__category {
  position: absolute;
  top: 6%;
  left: 3%;
  background: #ff962c;
  padding-inline: 20px;
  z-index: 10;
}

.seminer__title {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 0 27px 16px 18px;
  font-size: 20px;
  line-height: 1.25;
  color: white;
}

.seminer__article {
  overflow: hidden;
  border-radius: 20px;
}

.seminer__img img {
  transition: transform 0.5s ease;
}

.seminer__link:hover .seminer__img1 img,
.seminer__link:hover .seminer__img2 img,
.seminer__link:hover .seminer__img3 img {
  transform: scale(1.1);
}

.section-title__contents {
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

.section-title__title {
  font-weight: bold;
  font-size: 1.75rem;
  color: #0077b4;
}

.section-title__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.section-title__text {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.4285714286;
}

.section-title2__contents {
  width: 100%;
  margin-inline: auto;
}

.section-title2__title {
  width: 100%;
}

.section-title2__title img {
  width: 100%;
  height: 100%;
}

.section-title2__text {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1;
}

.layout-news {
  margin-top: 0.75rem;
}

.layout-pickUp {
  margin-top: 4.6875rem;
}

.layout-service {
  margin-top: 4.1875rem;
}

.layout-works {
  margin-top: 3.75rem;
}

.layout-seminer {
  margin-top: 3.75rem;
}

.layout-powerOfEditing {
  margin-top: 4.1875rem;
}

.layout-media {
  margin-top: 3.75rem;
}

.layout-project {
  margin-top: 3.75rem;
}

.layout-recruit {
  margin-top: 3.75rem;
  margin-bottom: 5.0625rem;
}

.card-category {
  display: inline;
  border-radius: 14px;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.5;
  color: white;
}

.powerOfEditing__section-title {
  max-width: 20.625rem;
  width: 100%;
}

.powerOfEditing__section-text-wrap {
  margin-top: 2.125rem;
  display: flex;
  flex-direction: column;
}

.powerOfEditing__section-text {
  font-weight: 900;
  font-size: 1.75rem;
  line-height: 1.2666666667;
}

.powerOfEditing__button {
  display: none;
}

.powerOfEditing__button-text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  padding-right: 9px;
  display: inline-block;
}

.powerOfEditing__button__arrow {
  border: 1px solid #1C1C1C;
  width: 19px;
  height: 19px;
}

.powerOfEditing__button__arrow.button__arrow.arrow::before {
  left: 4px;
  width: 10px;
}

.powerOfEditing__button__arrow.button__arrow.arrow::after {
  left: 10px;
  width: 3px;
  height: 3px;
}

.powerOfEditing__button:hover .button__arrow {
  transform: translateX(8px);
}

.powerOfEditing__button__arrow.button__arrow.arrow::before {
  background: #1C1C1C;
}

.powerOfEditing__button__arrow.button__arrow.arrow::after {
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
}

.powerOfEditing__inner {
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  /*スクロールバー非表示（Firefox）*/
  scrollbar-width: none;
}

/*スクロールバー非表示（Chrome・Safari）*/

.powerOfEditing__inner::-webkit-scrollbar {
  display: none;
}

.powerOfEditing__article-wrap {
  margin-top: 1.9375rem;
  display: flex;
  flex-wrap: nowrap;
  width: 1192px;
  padding-inline: 1.25rem;
}

.powerOfEditing__article {
  position: relative;
  margin-right: 28px;
  width: 309px;
}

.powerOfEditing__article:nth-last-child(1) {
  margin-right: 0;
}

.powerOfEditing__link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 25%;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
  border-radius: 0 0 20px 20px;
}

.powerOfEditing__img {
  width: 309px;
  height: 100%;
}

.powerOfEditing__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 309/408;
}

.powerOfEditing__category {
  position: absolute;
  top: 6%;
  left: 3%;
  background: #ff962c;
  padding-inline: 20px;
  z-index: 10;
}

.powerOfEditing__title {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 0 27px 16px 18px;
  font-size: 20px;
  line-height: 1.25;
  color: white;
}

.powerOfEditing__article {
  overflow: hidden;
  border-radius: 20px;
}

.powerOfEditing__img img {
  transition: transform 0.5s ease;
}

.powerOfEditing__link:hover .powerOfEditing__img1 img,
.powerOfEditing__link:hover .powerOfEditing__img2 img,
.powerOfEditing__link:hover .powerOfEditing__img3 img {
  transform: scale(1.1);
}

/*スクロールバー非表示（Chrome・Safari）*/

.page-philosophy::-webkit-scrollbar {
  display: none;
}

.page-philosophy__bg {
  background-image: url("../images/page-philosophy/page-philosophy-bg.webp");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  padding: 7.1875rem 0 9.6875rem;
}

.page-philosophy__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

.page-philosophy__contents {
  color: white;
}

.page-philosophy__en {
  max-width: 18.4375rem;
  width: 100%;
}

.page-philosophy__en img {
  width: 100%;
  height: 100%;
}

.page-philosophy__ja {
  font-size: 1.3125rem;
  line-height: 1;
}

.page-philosophy__title {
  margin-top: 7.1875rem;
  font-weight: 900;
  font-size: 2.625rem;
  letter-spacing: 0.1em;
  line-height: 1.1428571429;
}

.page-philosophy__text {
  margin-top: 4.4375rem;
  font-weight: 900;
  font-size: 1.75rem;
  line-height: 1.4285714286;
}

.page-philosophy__text2,
.page-philosophy__text3 {
  margin-top: 3.1875rem;
}

.page-philosophy__section-title--common {
  position: relative;
  display: block;
  text-align: center;
}

.page-philosophy__section-title--common::after {
  position: absolute;
  display: block;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 100%;
  height: 3px;
  background-color: #0077B4;
}

.page-mission__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.5625rem;
  margin-inline: auto;
}

/*スクロールバー非表示（Chrome・Safari）*/

.page-mission__inner::-webkit-scrollbar {
  display: none;
}

.page-mission__section-title img {
  max-width: 10.5rem;
  width: 100%;
  margin-inline: auto;
}

.page-mission__sub-title {
  margin-top: 4.4375rem;
  display: flex;
  justify-content: center;
}

.page-mission__sub-title img {
  max-width: 17.125rem;
  width: 100%;
  margin-inline: auto;
}

.page-mission__title {
  margin-top: 2.3125rem;
  font-weight: 900;
  font-size: 1.625rem;
  line-height: 1.1923076923;
  text-align: center;
  color: #383838;
}

.page-mission__text {
  margin-top: 2.375rem;
  font-weight: 900;
  text-align: center;
  color: #404040;
}

.page-mission__text1 {
  font-size: 1rem;
  line-height: 1.6875;
}

.page-mission__text2 {
  margin-top: 1.5625rem;
  font-size: 13px;
  line-height: 1.5384615385;
}

.page-vision__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.5625rem;
  margin-inline: auto;
}

/*スクロールバー非表示（Chrome・Safari）*/

.page-vision__inner::-webkit-scrollbar {
  display: none;
}

.page-vision__section-title img {
  max-width: 10.5rem;
  width: 100%;
  margin-inline: auto;
}

.page-vision__title-img {
  margin-top: 4.375rem;
  display: flex;
  justify-content: center;
}

.page-vision__title-img img {
  max-width: 40rem;
  width: 100%;
  margin-inline: auto;
}

.page-vision__text {
  margin-top: 2.3125rem;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
  color: #383838;
}

.page-message__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

/*スクロールバー非表示（Chrome・Safari）*/

.page-message__inner::-webkit-scrollbar {
  display: none;
}

.page-message__section-title {
  font-weight: 900;
  font-size: 3rem;
  line-height: 1;
  color: #0077B4;
}

.page-message__text {
  margin-top: 2.9375rem;
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  color: #404040;
}

.page-message__text2,
.page-message__text3,
.page-message__text4,
.page-message__text5,
.page-message__text6 {
  margin-top: 4.0625rem;
}

.page-message__img {
  margin-top: 5.25rem;
  text-align: center;
}

.page-message__img img {
  max-width: 17.875rem;
  width: 100%;
  margin-inline: auto;
}

.layout-page-mission {
  margin-top: 9.875rem;
}

.layout-page-vision {
  margin-top: 9.875rem;
}

.layout-page-message {
  margin-top: 9.875rem;
  margin-bottom: 5.0625rem;
}

.page-news__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin: 2rem auto 0;
}

/*スクロールバー非表示（Chrome・Safari）*/

.page-news__inner::-webkit-scrollbar {
  display: none;
}

.page-news__contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-news__section-title {
  max-width: 10.5rem;
  width: 100%;
}

.page-news__article-content {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin: 3.75rem auto 5.0625rem;
}

/*スクロールバー非表示（Chrome・Safari）*/

.page-news__article-content::-webkit-scrollbar {
  display: none;
}

.page-news__article {
  cursor: pointer;
  transition: 0.5s ease;
}

.page-news__article:hover {
  background-color: #f7f7f7;
  opacity: initial;
}

.page-news__article-img-wrap {
  margin-top: 3.75rem;
  width: 194px;
  margin-inline: auto;
}

.page-news__article-img-wrap img {
  width: 100%;
  aspect-ratio: 194/110;
  object-fit: cover;
}

.page-news__article-info {
  margin-top: 1.4375rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #000000;
  display: inline-flex;
  gap: 1.25rem;
  flex-wrap: wrap;
}

.page-news__article-tag {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 11px;
  line-height: 1;
  padding: 2px 15px;
  border: 1px solid #707070;
}

.page-news__article-title {
  margin-top: 0.9375rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  color: #383838;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.layout-page-news {
  margin-top: 2rem;
}

.selectBox__content {
  margin-top: 3.375rem;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.selectBox1 {
  width: 96px;
}

.selectBox2 {
  width: 126px;
}

.selectBox {
  position: relative;
}

.selectBox::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 6px 0 6px;
  border-color: #6e6e6e transparent transparent transparent;
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
  pointer-events: none;
}

select.selectBox__items {
  width: 100%;
  padding: 4px 10px;
  text-align: center;
  text-align-last: center;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #c6c6c6;
}

.pagination {
  margin-top: 3.8125rem;
  display: flex;
}

.pagination__inner {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.pagination__item {
  margin-right: 1rem;
}

.pagination__link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.25;
  color: #a7a7a7;
  transition: 0.3s;
}

.pagination__link--active {
  color: #1C1C1C;
  pointer-events: none;
}

.pagination__link--next,
.pagination__link--prev {
  color: #1C1C1C;
}

.page-news-detail__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin: 2rem auto 5.0625rem;
}

/*スクロールバー非表示（Chrome・Safari）*/

.page-news-detail__inner::-webkit-scrollbar {
  display: none;
}

.page-news-detail__pagination {
  margin-top: 1.375rem;
}

.page-news-detail__pagination__item {
  display: flex;
}

.page-news-detail__pagination__item img {
  max-width: 3.0625rem;
  width: 100%;
}

.page-news-detail__pagination__item > a {
  display: flex;
}

.page-news-detail__pagination-arrow {
  position: relative;
  display: inline-block;
  padding: 0 0 0 16px;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.page-news-detail__pagination-arrow::before,
.page-news-detail__pagination-arrow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.page-news-detail__pagination-arrow::before {
  right: 2px;
  width: 10px;
  height: 2px;
  background-color: #000000;
}

.page-news-detail__pagination-arrow::after {
  left: 3px;
  width: 6px;
  height: 6px;
  border-top: 2px solid #000000;
  border-right: 2px solid #000000;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.page-news-detail__image-wrap {
  margin-top: 2.5rem;
  max-width: 20.9375rem;
  width: 100%;
  margin-inline: auto;
}

.page-news-detail__image-wrap img {
  width: 100%;
  aspect-ratio: 335/207;
  object-fit: cover;
}

.page-news-detail__wrap {
  margin-top: 2.0625rem;
  display: flex;
  gap: 1.25rem;
}

.page-news-detail__tag {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 11px;
  line-height: 1;
  padding: 2px 15px;
  border: 1px solid #707070;
}

.page-news-detail__date {
  font-size: 1rem;
  line-height: 1;
  color: #000000;
  display: inline-flex;
  flex-wrap: wrap;
}

.page-news-detail__title {
  margin-top: 1.125rem;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #383838;
}

.page-news-detail__the-content p {
  margin-top: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.7142857143;
}

.page-news-detail__the-content .page-news-detail__bg-paleYellow {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #edede8;
  max-width: 48.25rem;
  width: 100%;
  padding: 0.875rem 1.3125rem;
}

.page-news-detail__the-content h3 {
  margin-top: 2.5rem;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25;
}

.page-news-detail__the-content .page-news-detail__img-wrap {
  margin-top: 1.25rem;
  max-width: 18.25rem;
  width: 100%;
  margin-inline: auto;
}

.page-news-detail__the-content .page-news-detail__img-wrap img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 292/335;
}

.page-news-detail__the-content ul {
  margin-top: 1.25rem;
}

.page-news-detail__the-content .page-news-detail__list + .page-news-detail__list {
  margin-top: 2.1875rem;
}

.page-news-detail__the-content .page-news-detail__list > p {
  margin-top: 0;
  font-weight: 400;
}

.page-news-detail__the-content li {
  list-style: none;
  /* デフォルトのアイコンを消す */
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.page-news-detail__the-content li::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  background-color: #1C1C1C;
  top: -1px;
  margin-right: 5px;
  overflow: hidden;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.page-news-detail__the-content li::after {
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

@media screen and (min-width: 375px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

@media screen and (min-width: 429px) {
  .mv__bg {
    margin-top: -100%;
  }
}

@media screen and (min-width: 600px) {
  .service__article-wrap2 {
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 768px) {
  .sp-br {
    display: none;
  }

  .pc-br {
    display: block;
  }

  .icon__text {
    font-size: 16px;
    line-height: 2.5625;
  }

  .inner {
    max-width: 1202px;
    padding-inline: 25px;
  }

  .header {
    height: 77px;
  }

  .header__logo img {
    max-width: 197px;
  }

  .drawer__menu-content {
    padding-bottom: 73px;
  }

  .drawer__footer {
    height: 73px;
  }

  .menu-lists {
    padding: 8rem 0 3.4375rem;
  }

  .menu-list {
    flex-direction: row;
  }

  .menu-list__news {
    margin-top: 0;
  }

  .menu-list__news-lists {
    margin-top: 11px;
  }

  .menu-list__other {
    margin-top: 0;
    max-width: min(33.4375vw, 428px);
  }

  .menu-list__other-content {
    max-width: min(23.125vw, 314px);
  }

  .menu-info {
    margin-top: 33px;
  }

  .arrow::before {
    left: 4px;
    width: 25px;
  }

  .arrow::after {
    left: 18px;
    width: 8px;
    height: 8px;
  }

  .header-spacer {
    height: 77px;
  }

  .loading__title img {
    max-width: 62.6875rem;
  }

  .mv__bg {
    margin-top: -80%;
    padding: 16.25rem 0 6.6875rem;
  }

  .mv__inner {
    max-width: 67.6875rem;
    padding: 0 1.5625rem;
  }

  .mv__title {
    padding-top: 9.375rem;
    font-size: 6.625rem;
  }

  .mv__text1 {
    font-size: 3.375rem;
  }

  .mv__text2 {
    max-width: 67.0625rem;
    margin-top: 1.25rem;
    font-size: 2.25rem;
  }

  .mv__button {
    padding-right: 2.5rem;
  }

  p.mv__button-text {
    font-size: 1.5625rem;
  }

  .mv__button__arrow {
    width: 33px;
    height: 33px;
  }

  .news__bg {
    max-width: 70.5rem;
    padding: 0 3.125rem 0 3.3125rem;
  }

  .news__contents {
    padding: 57px 0 28px;
  }

  .news__button-text {
    font-size: 1.9375rem;
  }

  .news__button__arrow {
    width: 33px;
    height: 33px;
  }

  .news__article-wrap1 {
    margin-top: 2.3125rem;
  }

  .news__content1 {
    max-width: 69.375rem;
    flex-direction: row;
  }

  .news__content-left {
    max-width: 31.25rem;
    padding-right: 2.5rem;
  }

  .news__category {
    font-size: 15px;
  }

  .news__title1 {
    margin-top: 0;
    font-size: 2.75rem;
  }

  .news__text1 {
    margin-top: 0.9375rem;
    font-size: 1rem;
  }

  .news__article1-img {
    margin-top: 0;
    max-width: 35.5rem;
  }

  .news__article-wrap2 {
    max-width: 69.375rem;
    margin-top: 3.8125rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.1875rem;
  }

  .news__text2,
  .news__text3,
  .news__text4 {
    font-size: 1rem;
  }

  .news__article2-img,
  .news__article3-img,
  .news__article4-img {
    margin-top: 0.375rem;
  }

  .pickUp__section-title {
    max-width: 459px;
  }

  .pickUp__section-text {
    margin-top: 0.6875rem;
    font-size: 50px;
    line-height: 1.1;
  }

  .pickUp__article-wrap {
    margin-top: 19px;
    grid-template-columns: 1fr 1fr;
    max-width: 1152px;
    gap: 21px;
  }

  .pickUp__article1 {
    max-width: 565px;
    grid-column: 1/2;
    grid-row: 1/3;
  }

  .pickUp__article2 {
    max-width: 565px;
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .pickUp__article3 {
    max-width: 565px;
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .pickUp__article4 {
    max-width: 1152px;
    grid-column: 1/3;
    grid-row: 3/4;
  }

  .pickUp__article5 {
    max-width: 565px;
    grid-column: 1/2;
    grid-row: 4/5;
  }

  .pickUp__article6 {
    max-width: 565px;
    grid-column: 2/3;
    grid-row: 4/5;
  }

  .pickUp__content1 {
    max-width: 304px;
    padding: 4.9375rem 0 5.625rem;
  }

  .pickUp__content2 {
    max-width: 477px;
    padding: 3.4375rem 0 3rem;
  }

  .pickUp__content3 {
    max-width: 477px;
    padding: 3.1875rem 0 3.375rem;
  }

  .pickUp__content4 {
    flex-direction: row;
    align-items: center;
    max-width: 920px;
    padding: 2.5625rem 0 2.25rem;
  }

  .pickUp__content5,
  .pickUp__content6 {
    max-width: 477px;
    padding: 2.75rem 0 3.375rem;
  }

  .pickUp__article1-img {
    max-width: 292px;
  }

  .pickUp__article2-img,
  .pickUp__article3-img,
  .pickUp__article5-img,
  .pickUp__article6-img {
    max-width: 155px;
    width: 100%;
    margin-right: 2.8125vw;
  }

  .pickUp__article4-img {
    max-width: 181px;
    width: 100%;
    margin-right: 3.1875rem;
  }

  .pickUp__title {
    margin-top: 15px;
    font-size: 26px;
    line-height: 1.1538461538;
  }

  .pickUp__text {
    margin-top: 21px;
    font-size: 16px;
    line-height: 1.5625;
  }

  .service__section-title {
    max-width: 335px;
    margin-right: 2.125rem;
  }

  .service__section-text {
    font-size: min(2.3437vw, 30px);
  }

  .service__button {
    display: block;
    margin-left: -170px;
  }

  .service__article-wrap {
    margin-top: 17px;
    grid-template-columns: 1fr 1fr;
    max-width: 1152px;
    gap: 21px;
  }

  .service__article1 {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .service__article2 {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .service__article3 {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .service__article4 {
    grid-column: 1/2;
    grid-row: 3/4;
  }

  .service__article5 {
    grid-column: 2/3;
    grid-row: 3/4;
  }

  .service__article-wrap2 {
    margin-top: 5rem;
    max-width: 1152px;
    margin-top: 17px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .service__content {
    max-width: 765px;
  }

  .service__content3,
  .service__content4,
  .service__content5 {
    max-width: 23.125rem;
  }

  .service__content3 img,
  .service__content4 img,
  .service__content5 img {
    aspect-ratio: 370/501;
  }

  .service__body1,
  .service__body2 {
    min-height: 148px;
  }

  .service__body3,
  .service__body4,
  .service__body5 {
    min-height: 206px;
  }

  .service__title1 {
    font-size: 33px;
    line-height: 1.1515151515;
  }

  .service__title2 {
    font-size: 24px;
    line-height: 1.3333333333;
  }

  .service__title3,
  .service__title4,
  .service__title5 {
    font-size: 24px;
    line-height: 1.3333333333;
  }

  .service__text {
    font-size: 18px;
    line-height: 1.3333333333;
  }

  .works__inner {
    padding-left: 25px;
  }

  .works__article-wrap {
    margin-top: 18px;
    max-width: 1650px;
  }

  .media__section-title {
    max-width: 122px;
  }

  .media__banner-wrap {
    max-width: 1152px;
    width: 100%;
    flex-direction: row;
    border: none;
    padding: 0;
  }

  .media__banner {
    width: 568px;
    min-height: 228px;
    border-radius: 20px;
    border: 1px solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .media__banner1 {
    margin-right: 1rem;
  }

  .media__banner3 {
    margin-top: 15px;
  }

  .media__banner-img1 {
    max-width: 396px;
    border-bottom: none;
    padding: 0;
  }

  .media__banner-img2 {
    max-width: 492px;
    border-bottom: none;
    padding: 0;
  }

  .media__banner-img3 {
    max-width: 396px;
    border-bottom: none;
    padding: 0;
  }

  .project__section-title {
    max-width: 168px;
  }

  .project__banner-bg {
    margin-top: 5px;
  }

  .project__banner-title {
    font-size: 54px;
    line-height: 1.1481481481;
    padding: 4.4375rem 0;
  }

  .recruit__section-title {
    max-width: 161px;
  }

  .recruit__banner {
    margin-top: 0.25rem;
  }

  .recruit__banner-bg {
    background-image: url("../images/top/recruit-img.webp");
    max-width: 1152px;
    height: 15.4375rem;
    padding-left: 34px;
    justify-content: flex-start;
    align-items: center;
  }

  .recruit__banner-title {
    justify-content: baseline;
    font-size: 60px;
    line-height: 1.1481481481;
    padding-left: 0;
  }

  .seminer__article-wrap {
    margin-top: 9px;
    width: 1202px;
    margin-inline: auto;
    padding-inline: 25px;
  }

  .seminer__article {
    width: 365px;
  }

  .seminer__img {
    width: 365px;
  }

  .seminer__img img {
    aspect-ratio: 365/482;
  }

  .section-title__contents {
    padding-inline: 25px;
  }

  .section-title2__contents {
    max-width: 1152px;
    display: flex;
    align-items: center;
  }

  .section-title2__title {
    margin-right: 1.25rem;
  }

  .section-title2__text {
    margin-top: 0;
    font-size: 20px;
  }

  .layout-news {
    margin-top: 8.0625rem;
  }

  .layout-pickUp {
    margin-top: 86px;
  }

  .layout-service {
    margin-top: 128px;
  }

  .layout-works {
    margin-top: 47px;
  }

  .layout-seminer {
    margin-top: 60px;
  }

  .layout-powerOfEditing {
    margin-top: 128px;
  }

  .layout-media {
    margin-top: 81px;
  }

  .layout-project {
    margin-top: 81px;
  }

  .layout-recruit {
    margin-top: 81px;
    margin-bottom: 150px;
  }

  .powerOfEditing__section-title {
    max-width: 781px;
  }

  .powerOfEditing__section-text-wrap {
    margin-top: 0.8125rem;
  }

  .powerOfEditing__section-text {
    font-size: min(2.3437vw, 30px);
  }

  .powerOfEditing__button {
    display: block;
    text-align: right;
  }

  .powerOfEditing__article-wrap {
    margin-top: 40px;
    width: 2302px;
    margin-inline: auto;
    padding-inline: 25px;
    row-gap: 40px;
  }

  .powerOfEditing__article {
    width: 365px;
  }

  .powerOfEditing__img {
    width: 365px;
  }

  .powerOfEditing__img img {
    aspect-ratio: 365/482;
  }

  .page-philosophy {
    overflow-x: auto;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
  }

  .page-philosophy__bg {
    width: 100%;
    width: fit-content;
    padding: 9.375rem 0 13.5rem;
  }

  .page-philosophy__inner {
    max-width: 1083px;
    width: 1083px;
    padding-inline: 25px;
  }

  .page-philosophy__contents {
    width: 1033px;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .page-philosophy__en {
    max-width: 446px;
  }

  .page-philosophy__ja {
    font-size: 34px;
  }

  .page-philosophy__title {
    margin-top: 6.5625rem;
    font-size: 92px;
    line-height: 1.097826087;
  }

  .page-philosophy__text {
    margin-top: 9.25rem;
    font-size: 50px;
    line-height: 1.4;
  }

  .page-philosophy__text2,
  .page-philosophy__text3 {
    margin-top: 5.625rem;
  }

  .page-philosophy__section-title--common {
    width: 1152px;
  }

  .page-mission__inner {
    max-width: 100%;
    width: 1202px;
    padding-inline: 25px;
    overflow-y: hidden;
    overflow-x: auto;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
  }

  .page-mission__contents {
    display: inline-block;
    align-items: center;
  }

  .page-mission__section-title img {
    max-width: 318px;
  }

  .page-mission__sub-title {
    margin-top: 6.0625rem;
  }

  .page-mission__sub-title img {
    max-width: 394px;
  }

  .page-mission__title {
    margin-top: 2.5rem;
    font-size: 53px;
    line-height: 1.2264150943;
    width: 898px;
    margin-inline: auto;
  }

  .page-mission__text {
    margin-top: 2.625rem;
  }

  .page-mission__text1 {
    font-size: 30px;
    line-height: 1.6666666667;
    width: 750px;
    margin-inline: auto;
  }

  .page-mission__text2 {
    margin-top: 1.8125rem;
    font-size: 22px;
    line-height: 2.0454545455;
  }

  .page-vision__inner {
    max-width: 100%;
    width: 1202px;
    padding-inline: 25px;
    overflow-y: hidden;
    overflow-x: auto;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
  }

  .page-vision__contents {
    display: inline-block;
    align-items: center;
  }

  .page-vision__section-title img {
    max-width: 251px;
  }

  .page-vision__title-img {
    margin-top: 6.8125rem;
  }

  .page-vision__title-img img {
    max-width: 626px;
  }

  .page-vision__text {
    margin-top: 2.5rem;
    font-size: 53px;
    line-height: 1.2264150943;
    width: 689px;
    margin-inline: auto;
  }

  .page-message__inner {
    max-width: 1050px;
    padding-inline: 25px;
    overflow-x: auto;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
  }

  .page-message__contents {
    max-width: 1000px;
    width: 1000px;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .page-message__section-title {
    font-size: 73px;
  }

  .page-message__text {
    margin-top: 3.3125rem;
    font-size: 28px;
    line-height: 1.3214285714;
    width: 1000px;
  }

  .page-message__text2,
  .page-message__text3,
  .page-message__text4,
  .page-message__text5,
  .page-message__text6 {
    margin-top: 5rem;
  }

  .page-message__img {
    margin-top: 2.9375rem;
    margin-left: auto;
  }

  .page-message__img img {
    max-width: 100%;
    width: 286px;
    margin-inline: inherit;
  }

  .layout-page-mission {
    margin-top: 9.6875rem;
  }

  .layout-page-vision {
    margin-top: 9.75rem;
  }

  .layout-page-message {
    margin-top: 9.8125rem;
    margin-bottom: 6.25rem;
  }

  .page-news {
    overflow-x: auto;
  }

  .page-news__inner {
    max-width: 100%;
    width: 1050px;
    padding-inline: 25px;
    margin: 2.75rem auto 0;
    overflow-y: hidden;
    overflow-x: auto;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
  }

  .page-news__contents {
    flex-direction: row;
    align-items: flex-end;
    width: 1000px;
  }

  .page-news__section-title {
    max-width: 16.5rem;
  }

  .page-news__article-content {
    max-width: 100%;
    width: 1050px;
    padding-inline: 25px;
    margin: 4.5625rem auto 6.25rem;
    overflow-x: auto;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
  }

  .page-news__article {
    width: 1000px;
    display: grid;
    grid-template-columns: 1fr 3.4536fr;
    grid-gap: 0px 1.8125rem;
    align-items: center;
    padding: 1.625rem 4.125rem 1.625rem 2.5625rem;
    margin-bottom: 3rem;
  }

  .page-news__article-img-wrap {
    margin-top: 0;
  }

  .page-news__article-info {
    flex-direction: column;
    font-size: 1rem;
    gap: 0.75rem;
    margin-top: 0;
  }

  .page-news__article-tag {
    font-size: 13px;
  }

  .page-news__article-title {
    margin-top: 0.75rem;
    font-size: 1.375rem;
    line-height: 1.5909090909;
    -webkit-line-clamp: 3;
    width: 670px;
  }

  .layout-page-news {
    margin-top: 2.75rem;
  }

  .selectBox__content {
    margin-top: 0;
    margin-left: auto;
    flex-shrink: 0;
  }

  .selectBox1 {
    width: 134px;
    margin-right: 3.25rem;
  }

  .selectBox2 {
    width: 206px;
  }

  .pagination__link {
    font-size: 16px;
    line-height: 1.25;
  }

  .page-news-detail__inner {
    max-width: 100%;
    width: 890px;
    padding-inline: 25px;
    margin: 2.75rem auto 6.25rem;
    overflow-x: auto;
    /*スクロールバー非表示（IE・Edge）*/
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
  }

  .page-news-detail__contents {
    width: 840px;
  }

  .page-news-detail__pagination {
    margin-top: 2.875rem;
  }

  .page-news-detail__image-wrap {
    margin-top: 1.8125rem;
    max-width: 100%;
    width: 839px;
  }

  .page-news-detail__image-wrap img {
    max-width: 839px;
    width: 839px;
    aspect-ratio: 839/519;
  }

  .page-news-detail__wrap {
    margin-top: 2.625rem;
    gap: 0.75rem;
    width: 840px;
    white-space: nowrap;
  }

  .page-news-detail__tag {
    font-size: 13px;
  }

  .page-news-detail__date {
    flex-direction: column;
  }

  .page-news-detail__title {
    margin-top: 0.9375rem;
    font-size: 1.375rem;
    line-height: 1.5909090909;
  }

  .page-news-detail__the-content p {
    margin-top: 1.3125rem;
    font-size: 1rem;
    line-height: 1.625;
  }

  .page-news-detail__the-content .page-news-detail__bg-paleYellow {
    padding: 0.875rem 1.875rem;
  }

  .page-news-detail__the-content h3 {
    font-size: 1.125rem;
    line-height: 1.6666666667;
  }

  .page-news-detail__the-content .page-news-detail__img-wrap {
    margin-top: 1.875rem;
    max-width: 34.75rem;
  }

  .page-news-detail__the-content .page-news-detail__img-wrap img {
    aspect-ratio: 556/638;
  }

  .page-news-detail__the-content ul {
    margin-top: 1.875rem;
  }
}

@media screen and (min-width: 1000px) {
  .mv__title {
    padding-top: 18.75rem;
    font-size: 9.8125rem;
  }

  .mv__text1 {
    font-size: 4.875rem;
    line-height: 1.141025641;
  }

  .mv__text2 {
    margin-top: 2.6875rem;
    font-size: 2.5rem;
    line-height: 1.375;
  }

  .news__title1 {
    font-size: 3.4375rem;
  }

  .service__section-title-wrap {
    display: flex;
    align-items: center;
  }

  .service__article-wrap {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .service__article2 {
    grid-column: 3/4;
    grid-row: 1/2;
  }

  .service__article3 {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .service__article4 {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .service__article5 {
    grid-column: 3/4;
    grid-row: 2/3;
  }

  .recruit__banner-title {
    font-size: 81px;
  }

  .powerOfEditing__section-title-wrap {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 1058px) {
  .page-philosophy__bg {
    width: 100%;
    padding: 9.375rem 0 13.5rem;
  }
}

@media screen and (min-width: 1150px) {
  .works__icon-link {
    order: 2;
    margin-left: auto;
  }

  .works__inner {
    max-width: 1241px;
    width: 100%;
  }

  .seminer__icon-link {
    order: 2;
    margin-left: auto;
  }

  .section-title__contents {
    max-width: 1202px;
    display: flex;
    align-items: center;
  }

  .section-title__title {
    font-size: 44px;
    padding-right: 2.1093vw;
    order: 0;
  }

  .section-title__content {
    display: contents;
    padding-right: 8.3593vw;
  }

  .section-title__text {
    margin-top: 0;
    font-size: 20px;
    order: 1;
  }

  .powerOfEditing__icon-link {
    order: 2;
    margin-left: auto;
  }
}

@media screen and (min-width: 1200px) {
  .powerOfEditing__article-wrap {
    flex-wrap: wrap;
    width: 1230px;
  }
}

@media screen and (min-width: 1203px) {
  .media__banner2 {
    margin: 0;
  }
}

@media screen and (min-width: 1240px) {
  .powerOfEditing__section-text-wrap {
    flex-direction: row;
  }

  .powerOfEditing__button {
    margin-left: -170px;
    text-align: left;
    margin-top: 2.8125rem;
  }
}

@media screen and (min-width: 1281px) {
  .works__inner {
    margin-right: auto;
  }
}

@media screen and (min-width: 1560px) {
  .mv__bg {
    margin-top: -70%;
  }
}

@media screen and (min-width: 1600px) {
  .mv__bg {
    margin-top: -60%;
  }
}

@media screen and (min-width: 1920px) {
  .mv__bg {
    margin-top: -30%;
  }
}

@media screen and (max-width: 950px) {
  .footer-logo {
    padding: 19px 0;
  }

  .footer-logo__items {
    flex-direction: column;
  }

  .footer-logo__item-wrap {
    margin-inline: auto;
  }

  .footer-logo__item {
    max-width: 8.875rem;
    width: 100%;
    margin-right: 1.4375rem;
  }

  .footer-logo__item-wrap > div:nth-child(2) {
    max-width: 10.625rem;
    width: 100%;
    margin-right: 0;
  }

  .footer-logo__item img {
    width: 100%;
    height: 100%;
  }

  .footer-logo__item.footer-logo__item--last {
    margin-inline: auto;
  }
}