@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//service
.service__section-title-wrap {
    @media screen and (min-width: 1000px) {
        display: flex;
        align-items: center;
    }
}

.service__section-title {
    max-width: rem(335);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 335px;
        margin-right: rem(34);
    }
}

.service__section-text-wrap {
    display: flex;
    align-items: flex-end;
}

.service__section-text {
    font-weight: 900;
    font-size: rem(28);
    line-height: calc(38 / 30);
    @media screen and (min-width: $tab) {
        font-size: min(2.3437vw, 30px);
    }
}

//一覧ボタン
.service__button {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
        margin-left: -170px;
    }
}

.service__button-text {
    font-family: $roboto;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    padding-right: 9px;
    display: inline-block;
    }


.service__button__arrow {
    border: 1px solid $txt-color;
        width: 19px;
        height: 19px;
}

.service__button__arrow.button__arrow.arrow::before {
    left: 4px;
    width: 10px;
}

.service__button__arrow.button__arrow.arrow::after {
    left: 10px;
    width: 3px;
    height: 3px;
}

//ホバー矢印
.service__button:hover .button__arrow {
    transform: translateX(8px);
}

.service__button__arrow.button__arrow.arrow::before {
    background: $txt-color;
}

.service__button__arrow.button__arrow.arrow::after {
    border-top: 1px solid $txt-color;
    border-right: 1px solid $txt-color;
}

.service__article-wrap {
    margin-top: rem(80);
    max-width: rem(710);
    margin-inline: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    @media screen and (min-width: $tab) {
        margin-top: 17px;
        grid-template-columns: 1fr 1fr;
        max-width: 1152px;
        gap: 21px;
    }
    @media screen and (min-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.service__article {
    width: 100%;
    height: 100%;
}

.service__article1 {
    width: 100%;
    height: 100%;
    @media screen and (min-width: $tab) {
        grid-column: 1/3;
        grid-row: 1/2;
    }
}

.service__article2 {
    width: 100%;
    height: 100%;
    @media screen and (min-width: $tab) {
        grid-column: 1/2;
        grid-row: 2/3;
    }
    @media screen and (min-width: 1000px) {
        grid-column: 3/4;
        grid-row: 1/2;
    }
}

.service__article3 {
    width: 100%;
    height: 100%;
    @media screen and (min-width: $tab) {
        grid-column: 2/3;
        grid-row: 2/3;
    }
    @media screen and (min-width: 1000px) {
        grid-column: 1/2;
        grid-row: 2/3;
    }
}

.service__article4 {
    width: 100%;
    height: 100%;
    @media screen and (min-width: $tab) {
        grid-column: 1/2;
        grid-row: 3/4;
    }
    @media screen and (min-width: 1000px) {
        grid-column: 2/3;
        grid-row: 2/3;
    }
}

.service__article5 {
    width: 100%;
    height: 100%;
    @media screen and (min-width: $tab) {
        grid-column: 2/3;
        grid-row: 3/4;
    }
    @media screen and (min-width: 1000px) {
        grid-column: 3/4;
        grid-row: 2/3;
    }
}

.service__article-wrap2 {
    margin-top: rem(20);
    margin-inline: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: rem(20);
    gap: rem(20);
    @media screen and (min-width: 600px) {
        max-width: 100%;
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: $tab) {
        margin-top: rem(80);
        max-width: 1152px;
        margin-top: 17px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.service__content {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 20px;
    @media screen and (min-width: $tab) {
        max-width: 765px;
    }
}

.service__article-img img {
    width: 100%;
    height: 100%;
}

.service__article-img::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.11);
}

// .service__article1-img {
//     width: 100%;
//     @media screen and (min-width: $tab) {
//         max-width: 765px;
//     }
// }

.service__content3,
.service__content4,
.service__content5 {
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(370);
    }
}

.service__content3 img,
.service__content4 img,
.service__content5 img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 328 / 304;
        @media screen and (min-width: $tab) {
        aspect-ratio: 370 / 501;
    }
}

.service__body {
    width: 100%;
    background-color: #e3e3e3;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 12px 23px 20px 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.service__body1,
.service__body2 {
    min-height: 130px;
    @media screen and (min-width: $tab) {
        min-height: 148px;
    }
}

.service__body3,
.service__body4,
.service__body5 {
    min-height: 130px;
    @media screen and (min-width: $tab) {
        min-height: 206px;
    }
}

.service__title {
    font-weight: 900;
    color: #2680eb;
}

.service__title1 {
    font-size: rem(26);
    line-height: calc(30 / 26);
    @media screen and (min-width: $tab) {
        font-size: 33px;
        line-height: calc(38 / 33);
    }
}

.service__title2 {
    font-size: rem(26);
    line-height: calc(30 / 26);
    @media screen and (min-width: $tab) {
        font-size: 24px;
        line-height: calc(32 / 24);
    }
}

.service__title3,
.service__title4,
.service__title5 {
    font-size: rem(21);
    line-height: calc(24 / 21);
    @media screen and (min-width: $tab) {
        font-size: 24px;
        line-height: calc(32 / 24);
    }
}

.service__text {
    font-size: 13px;
    line-height: calc(18 / 13);
    font-weight: 300;
    @media screen and (min-width: $tab) {
        font-size: 18px;
        line-height: calc(24 / 18);
    }
}

//serviceホバー画像拡大
.service__content {
    overflow: hidden;
}
.service__article-img {
    transition: transform 0.5s ease;
}

.service__link:hover .service__article1-img,
.service__link:hover .service__article2-img,
.service__link:hover .service__article3-img,
.service__link:hover .service__article4-img,
.service__link:hover .service__article5-img,
.service__link:hover .service__article6-img,
.service__link:hover .service__article7-img,
.service__link:hover .service__article8-img,
.service__link:hover .service__article9-img {
    transform: scale(1.1);
}
