@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//header-spacer
.header-spacer {
	height: 57px;
	@media screen and (min-width:$tab) {
		height: 77px;
	}
}



