@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//inner 
.inner {
	max-width: rem(710);
    width: 100%;
	padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 1202px;
		padding-inline: 25px;
    }
}



