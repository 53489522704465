@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-media {
    margin-top: rem(60);
    @media screen and (min-width: $tab) {
        margin-top: 81px;
    }
}
