@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//recruit
.recruit__section-title {
    max-width: rem(130);
    @media screen and (min-width: $tab) {
        max-width: 161px;
    }
}

.recruit__banner {
    margin-top: rem(14);
    @media screen and (min-width: $tab) {
        margin-top: rem(4);
    }
}

.recruit__banner-bg {
    background-image: url("../images/top/recruit-img-sp.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: rem(670);
    width: 100%;
    height: rem(250);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        background-image: url("../images/top/recruit-img.webp");
        max-width: 1152px;
        height: rem(247);
        padding-left: 34px;
        justify-content: flex-start;
        align-items: center;
    }
}

.recruit__banner-title {
    font-size: rem(47);
    line-height: calc(54 / 47);
    font-weight: 900;
    letter-spacing: 0.1em;
    color: $white;
    z-index: 2;
    @media screen and (min-width: $tab) {
        justify-content: baseline;
        font-size: 60px;
        line-height: calc(93 / 81);
        padding-left: 0;
    }
    @media screen and (min-width: 1000px) {
        font-size: 81px;
    }
}
