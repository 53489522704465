@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//lodinganimation
.area {
    width: 100vw;
    overflow: hidden;
}
.wrap {
    position: relative;
    height: 100vh;
}

.loading__title {
    opacity: 0;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    will-change: opacity;
    // transform-origin: 55% 52.5%;
    // @media screen and (min-width: $tab) {
    //     transform-origin: 54% 56%;
    //     }
    // @media screen and (min-width: 1600px) {
    //     transform-origin: 53.6% 54.5%;
    //     }
    // @media screen and (min-width: 1750px) {
    //     transform-origin: 54% 54.5%;
    //     }
    // @media screen and (min-width: 1920px) {
    //     transform-origin: 54% 54.2%;
    //     }
}

.loading__title img {
    max-width: rem(327);
    width: 100%;
    height: 80%;
    @media screen and (min-width: $tab) {
        max-width: rem(1003);
    }
}

.mv__area {
    // width: 100vw;
}

.mv__bg {
    opacity: 0;
    margin-top: -230%;
    width: 100%;
    // height: auto; /* 適切な高さに変更 */
    background-image: url("../images/top/mv-bg.webp");
    background-size: cover;
    background-position: top;
    z-index: 5;
    padding: rem(265) 0 rem(42);
    @media screen and (min-width: 429px) {
        margin-top: -100%;
    }
    @media screen and (min-width: $tab) {
        margin-top: -80%;
        padding: rem(260) 0 rem(107);
    }
    @media screen and (min-width: 1560px) {
        margin-top: -70%;
    }
    @media screen and (min-width: 1600px) {
        margin-top: -60%;
    }
    @media screen and (min-width: 1920px) {
        margin-top: -30%;
    }
}

.mv__inner {
    max-width: rem(706);
    width: 100%;
    padding: 0 rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(1083);
        padding: 0 rem(25);
    }
}

.mv__title {
    padding-top: rem(235);
    font-size: rem(56);
    width: 100%;
    text-align: center;
    font-weight: 900;
    line-height: 1;
    color: $white;
    z-index: 10;
    border: 1px solid rgba(0, 0, 0, 0);
    @media screen and (min-width: $tab) {
        padding-top: rem(150);
        font-size: rem(106);
    }
    @media screen and (min-width: 1000px) {
        padding-top: rem(300);
        font-size: rem(157);
    }
}

.mv__lead-content {
    margin-top: rem(282);
    width: 100%;
}

.mv__lead {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    letter-spacing: 0.1em;
    color: #fff;
}

.mv__text1 {
    font-size: rem(44);
    line-height: calc(48 / 44);
    font-weight: 900;
    @media screen and (min-width: $tab) {
        font-size: rem(54);
    }
    @media screen and (min-width: 1000px) {
        font-size: rem(78);
        line-height: calc(89 / 78);
    }
}

.mv__text2 {
    margin-top: rem(10);
    font-size: rem(24);
    line-height: calc(32 / 24);
    max-width: rem(666);
    width: 100%;
    font-weight: 800;
    @media screen and (min-width: $tab) {
        max-width: rem(1073);
        margin-top: rem(20);
        font-size: rem(36);
    }
    @media screen and (min-width: 1000px) {
        margin-top: rem(43);
        font-size: rem(40);
        line-height: calc(55 / 40);
    }
}

.mv__button-link {
    margin-top: rem(14);
    margin-left: auto;
}

.mv__button {
    text-align: right;
    display: inline-flex;
    justify-content: flex-end;
    @media screen and (min-width: $tab) {
        padding-right: rem(40);
    }
}

p.mv__button-text {
    font-size: rem(16);
    color: $white;
    padding-right: 14px;
    @media screen and (min-width: $tab) {
        font-size: rem(25);
    }
}

.mv__button__arrow {
    border: 1px solid $white;
    width: 22px;
    height: 22px;
    @media screen and (min-width: $tab) {
        width: 33px;
        height: 33px;
    }
}

.mv__button:hover .mv__button__arrow {
    transform: translateX(8px);
}
