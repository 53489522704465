@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.header {
	background-color: $white;
	position: fixed;
	width: 100%;
	height: 57px;
	z-index: 999;
	@media screen and (min-width:$tab) {
		height: 77px;
	}
}

.header__inner {
	display: flex;
	justify-content: space-between;
	padding: 15px;
	height: inherit;
}

.header__logo img {
	max-width: rem(127);
	width: 100%;
	@media screen and (min-width:$tab) {
		max-width: 197px;
		}
}
