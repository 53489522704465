@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//page-mission
.page-mission__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(25);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 100%;
        width: 1202px;
        padding-inline: 25px;
        overflow-y: hidden;
        overflow-x: auto;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
}
/*スクロールバー非表示（Chrome・Safari）*/
.page-mission__inner::-webkit-scrollbar {
    display: none;
}

.page-mission__contents {
    @media screen and (min-width: $tab) {
        display: inline-block;
        align-items: center;
    }
}

.page-mission__section-title img {
    max-width: rem(168);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 318px;
    }
}

.page-mission__sub-title {
    margin-top: rem(71);
    display: flex;
    justify-content: center;
    @media screen and (min-width: $tab) {
        margin-top: rem(97);
    }
}

.page-mission__sub-title img {
    max-width: rem(274);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 394px;
    }
}

.page-mission__title {
    margin-top: rem(37);
    font-weight: 900;
    font-size: rem(26);
    line-height: calc(31 / 26);
    text-align: center;
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
        font-size: 53px;
        line-height: calc(65 / 53);
        width: 898px;
        margin-inline: auto;
    }
}

.page-mission__text {
    margin-top: rem(38);
    font-weight: 900;
    text-align: center;
    color: $sub-black2;
    @media screen and (min-width: $tab) {
        margin-top: rem(42);
    }
}

.page-mission__text1 {
    font-size: rem(16);
    line-height: calc(27 / 16);
    @media screen and (min-width: $tab) {
        font-size: 30px;
        line-height: calc(50 / 30);
        width: 750px;
        margin-inline: auto;
    }
}

.page-mission__text2 {
    margin-top: rem(25);
    font-size: 13px;
    line-height: calc(20 / 13);
    @media screen and (min-width: $tab) {
        margin-top: rem(29);
        font-size: 22px;
        line-height: calc(45 / 22);
    }
}
