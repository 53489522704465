@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-page-vision  {
    margin-top: rem(158);
    @media screen and (min-width: $tab) {
        margin-top: rem(156)
    }
}
