@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-works {
    margin-top: rem(60);
    @media screen and (min-width: $tab) {
        margin-top: 47px;
    }
}
