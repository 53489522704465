@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//menu-info

.menu-info {
	margin-top: 46px;
	text-align: right;
	@media screen and (min-width: $tab) {
		margin-top: 33px;
	}
}

.menu-info__search {
	height: 19px;
	display: flex;
    justify-content: flex-end;
}

#form {
	position: relative;
	max-width: 151px;
	width: 100%;
	height: inherit;
	border-radius: 10px;
	background-color: transparent;
	padding-right: 18px;
}
#sbox {
	height: inherit;
	padding: 0 10px;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 10px;
	border: 1px solid #fff;
	outline: 0;
	background-color: transparent;
	color: $white;
	width: 100%;
}
#sbox::placeholder {
	color: $white;
}
button#sbtn {
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 10px;
	height: inherit;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
#sbtn::before {
	content: "";
	width: 13px;
	height: 13px;
	background-image: url("../images/search-icon.svg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	flex-shrink: 0;
	z-index: 10;
}
.menu-info__search-icon:hover {
	color: #7fbfff;
}

.menu-info__contact {
	margin-top: 16px;
	font-size: 13px;
	line-height: calc(13 / 20);
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
}

.menu-info__contact-icon {
	max-width: 21px;
	width: 100%;
	margin-right: 5px;
}

.menu-info__sns {
	margin-top: 13px;
	display: flex;
	justify-content: flex-end;
}

a.menu-info__sns-icon {
	font-family: $roboto;
	font-weight: bold;
	font-size: 13px;
	line-height: calc(13 / 33);
}

.menu-info__sns > div:nth-child(1) {
	padding-right: rem(36);
}

.menu-info__sns > div:nth-child(2) {
	padding-right: rem(19);
}

.menu-info__tel {
	margin-top: 14px;
	display: flex;
	justify-content: flex-end;
	font-size: 13px;
	line-height: 1;
    white-space: nowrap;
}

.menu-info__tel > a:nth-child(1) {
	margin-right: rem(16);
}

.menu-info__copyright {
	margin-top: 11px;
	font-size: 11px;
	line-height: 1;
}
