@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//selectBox

.selectBox__content {
    margin-top: rem(54);
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: $tab) {
        margin-top: 0;
        margin-left: auto;
        flex-shrink: 0;
    }
}

.selectBox1 {
    width: 96px;
    @media screen and (min-width: $tab) {
        width: 134px;
        margin-right: rem(52);
    }
}

.selectBox2 {
    width: 126px;
    @media screen and (min-width: $tab) {
        width: 206px;
    }
}

.selectBox {
    position: relative;
}
.selectBox::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 6px 0 6px;
    border-color: #6e6e6e transparent transparent transparent;
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    pointer-events: none;
}

select.selectBox__items {
    width: 100%;
    padding: 4px 10px;
    text-align: center;
    text-align-last: center;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 1px solid #c6c6c6;
}
