@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//section-title2

.section-title2__contents {
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab){
        max-width: 1152px;
        display: flex;
        align-items: center;
    }
}

.section-title2__title {
    width: 100%;
    @media screen and (min-width: $tab){
       margin-right: rem(20);
    }
}

.section-title2__title img {
    width: 100%;
    height: 100%;
}

.section-title2__text {
    margin-top: 5px;
    font-size: 14px;
    line-height: 1;
    @media screen and (min-width: $tab) {
        margin-top: 0;
        font-size: 20px;
    }
}

