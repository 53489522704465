@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//project
.project__section-title {
    max-width: rem(136);
    @media screen and (min-width: $tab) {
        max-width: 168px;
    }
}

.project__banner-bg {
    margin-top: rem(15);
    max-width: 1152px;
    width: 100%;
    border-radius: 20px;
    background: #a1b2c6;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: 5px;
    }
}

.project__banner-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: rem(41);
    line-height: calc(47 / 41);
    letter-spacing: 0.1em;
    line-height: 1;
    color: $white;
    padding: rem(16) 0;
    @media screen and (min-width: $tab) {
        font-size: 54px;
        line-height: calc(62 / 54);
        padding: rem(71) 0;
    }
}
