@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//page-news-detail

.page-news-detail__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin: rem(32) auto rem(81);
    @media screen and (min-width: $tab) {
        max-width: 100%;
        width: 890px;
        padding-inline: 25px;
        margin: rem(44) auto rem(100);
        overflow-x: auto;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
}
/*スクロールバー非表示（Chrome・Safari）*/
.page-news-detail__inner::-webkit-scrollbar {
    display: none;
}

.page-news-detail__contents {
    @media screen and (min-width: $tab) {
        width: 840px;
    }
}

.page-news-detail__pagination {
    margin-top: rem(22);
    @media screen and (min-width: $tab) {
        margin-top: rem(46);
    }
}

.page-news-detail__pagination__item {
    // max-width: rem(49);
    // width: 100%;
    display: flex;
}

.page-news-detail__pagination__item img {
    max-width: rem(49);
    width: 100%;
}

.page-news-detail__pagination__item > a {
    display: flex;
}

//ニュース詳細ページネーション矢印
.page-news-detail__pagination-arrow {
    position: relative;
    display: inline-block;
    padding: 0 0 0 16px;
    color: #000;
    vertical-align: middle;
    text-decoration: none;
    font-size: 15px;
}
.page-news-detail__pagination-arrow::before,
.page-news-detail__pagination-arrow::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}
.page-news-detail__pagination-arrow::before {
    right: 2px;
    width: 10px;
    height: 2px;
    background-color: $black;
}
.page-news-detail__pagination-arrow::after {
    left: 3px;
    width: 6px;
    height: 6px;
    border-top: 2px solid $black;
    border-right: 2px solid $black;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.page-news-detail__image-wrap {
    margin-top: rem(40);
    max-width: rem(335);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: rem(29);
        max-width: 100%;
        width: 839px;
    }
}

.page-news-detail__image-wrap img {
    width: 100%;
    aspect-ratio: 335 / 207;
    object-fit: cover;
    @media screen and (min-width: $tab) {
        max-width: 839px;
        width: 839px;
        aspect-ratio: 839 / 519;
    }
}

.page-news-detail__wrap {
    margin-top: rem(33);
    display: flex;
    gap: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(42);
        gap: rem(12);
        width: 840px;
        white-space: nowrap;
    }
}

.page-news-detail__tag {
    font-family: $roboto;
    font-weight: bold;
    font-size: 11px;
    line-height: 1;
    padding: 2px 15px;
    border: 1px solid #707070;
    @media screen and (min-width: $tab) {
        font-size: 13px;
    }
}

.page-news-detail__date {
    font-size: rem(16);
    line-height: 1;
    color: $black;
    display: inline-flex;
    flex-wrap: wrap;
    @media screen and (min-width: $tab) {
        flex-direction: column;
    }
}

.page-news-detail__title {
    margin-top: rem(18);
    font-weight: 600;
    font-size: rem(20);
    line-height: calc(30 / 20);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        margin-top: rem(15);
        font-size: rem(22);
        line-height: calc(35 / 22);
    }
}

//投稿内容のCSS
.page-news-detail__the-content {
    & p {
        margin-top: rem(20);
        font-size: rem(14);
        line-height: calc(24 / 14);
        @media screen and (min-width: $tab) {
            margin-top: rem(21);
            font-size: rem(16);
            line-height: calc(26 / 16);
        }
    }
    & .page-news-detail__bg-paleYellow {
        font-size: rem(14);
        line-height: calc(20 / 14);
        background-color: #edede8;
        max-width: rem(772);
        width: 100%;
        padding: rem(14) rem(21);
        @media screen and (min-width: $tab) {
            padding: rem(14) rem(30);
        }
    }
    & h3 {
        margin-top: rem(40);
        font-weight: bold;
        font-size: rem(16);
        line-height: calc(20 / 16);
        @media screen and (min-width: $tab) {
            font-size: rem(18);
            line-height: calc(30 / 18);
        }
    }
    & .page-news-detail__img-wrap {
        margin-top: rem(20);
        max-width: rem(292);
        width: 100%;
        margin-inline: auto;
        @media screen and (min-width: $tab) {
            margin-top: rem(30);
            max-width: rem(556);
        }
    }
    & .page-news-detail__img-wrap img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 292 / 335;
        @media screen and (min-width: $tab) {
            aspect-ratio: 556 / 638;
        }
    }
    & ul {
        margin-top: rem(20);
        @media screen and (min-width: $tab) {
            margin-top: rem(30);
        }
    }
    & .page-news-detail__list + .page-news-detail__list {
        margin-top: rem(35);
    }
    & .page-news-detail__list > p {
        margin-top: 0;
        font-weight: 400;
    }
}

.page-news-detail__the-content li {
    list-style: none; /* デフォルトのアイコンを消す */
    margin: 0;
    padding: 0;
    font-weight: bold;
}
.page-news-detail__the-content li::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background-color: $txt-color;
    top: -1px;
    margin-right: 5px;
    overflow: hidden;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.page-news-detail__the-content li::after {
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
