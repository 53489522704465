@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//セミナー情報
.seminer__icon-link {
    @media screen and (min-width: 1150px) {
        order: 2;
        margin-left: auto;
    }
}

.seminer__inner {
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
}

/*スクロールバー非表示（Chrome・Safari）*/
.seminer__inner::-webkit-scrollbar {
    display: none;
}

.seminer__article-wrap {
    margin-top: rem(20);
    display: flex;
    flex-wrap: nowrap;
    width: 1192px;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: 9px;
        width: 1202px;
        margin-inline: auto;
        padding-inline: 25px;
    }
}

.seminer__article {
    position: relative;
    margin-right: 28px;
    width: 309px;
    @media screen and (min-width: $tab) {
        width: 365px;
    }
}

.seminer__article:nth-last-child(1) {
    margin-right: 0;
}

.seminer__link::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 25%;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
    border-radius: 0 0 20px 20px;
}

.seminer__img {
    width: 309px;
    height: 100%;
    @media screen and (min-width: $tab){
        width: 365px;
    }
}

.seminer__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 309 / 408;
    @media screen and (min-width: $tab){
        aspect-ratio: 365 / 482;
    }
}

.seminer__category {
    position: absolute;
    top: 6%;
    left: 3%;
    background: #ff962c;
    padding-inline: 20px;
    z-index: 10;
}

.seminer__title {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 0 27px 16px 18px;
    font-size: 20px;
    line-height: calc(25 / 20);
    color: $white;
}

//seminerホバー画像拡大
.seminer__article {
    overflow: hidden;
    border-radius: 20px;
}
.seminer__img img {
    transition: transform 0.5s ease;
}

.seminer__link:hover .seminer__img1 img,
.seminer__link:hover .seminer__img2 img,
.seminer__link:hover .seminer__img3 img {
    transform: scale(1.1);
}


