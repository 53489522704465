@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//page-message

.page-message__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 1050px;
        padding-inline: 25px;
        overflow-x: auto;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
}
/*スクロールバー非表示（Chrome・Safari）*/
.page-message__inner::-webkit-scrollbar {
    display: none;
}

.page-message__contents {
    @media screen and (min-width: $tab) {
        max-width: 1000px;
        width: 1000px;
        overflow-y: hidden;
        overflow-x: auto;
    }
}

.page-message__section-title {
    font-weight: 900;
    font-size: rem(48);
    line-height: 1;
    color: $blue;
    @media screen and (min-width: $tab) {
        font-size: 73px;
    }
}

.page-message__text {
    margin-top: rem(47);
    font-weight: 900;
    font-size: rem(18);
    line-height: calc(28 / 18);
    color: $sub-black2;
    @media screen and (min-width: $tab) {
        margin-top: rem(53);
        font-size: 28px;
        line-height: calc(37 / 28);
        width: 1000px;
    }
}

.page-message__text2,
.page-message__text3,
.page-message__text4,
.page-message__text5,
.page-message__text6 {
    margin-top: rem(65);
    @media screen and (min-width: $tab) {
        margin-top: rem(80);
    }
}

.page-message__img {
    margin-top: rem(84);
    text-align: center;
    @media screen and (min-width: $tab) {
        margin-top: rem(47);
        margin-left: auto;
    }
}

.page-message__img img {
    max-width: rem(286);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 100%;
        width: 286px;
        margin-inline: inherit;
    }
}
