@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.hamburger {
	position: relative;
}

.hamburger__btn {
	width: 100%;
	display: block;
	width: 39px;
	height: 39px;
	position: absolute;
	right: 0;
	z-index: 999; /*追記：いつも一番上*/
	cursor: pointer;
}

button.hamburger__btn {
	border: none;
	outline: none;
	background: transparent;
}

.bar {
	width: 39px;
	height: 2px;
	display: block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background-color: #7E7E7E;
}

.bar-top {
	top: 10px;
}
.bar-middle {
	top: 50%;
	transform: translate(-50%, -50%);
}
.bar-bottom {
	bottom: 10px;
}

.hamburger__btn.close .bar-top {
	transform: translate(-50%, 10px) rotate(45deg);
	transition: transform 0.3s;
	background-color: $white;
}

.hamburger__btn.close .bar-middle {
	opacity: 0;
	transition: opacity 0.3s;
	background-color: $white;
}

.hamburger__btn.close .bar-bottom {
	transform: translate(-50%, -8px) rotate(-45deg);
	transition: transform 0.3s;
	background-color: $white;
}
