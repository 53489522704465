@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//works出版実績
.works__icon-link {
    @media screen and (min-width: 1150px) {
        order: 2;
        margin-left: auto;
    }
}

.works__icon-wrap {
    text-wrap: nowrap;
}

.works__inner {
    margin-right: 0;
    padding-right: 0;
    margin-left: auto;
    padding-left: rem(20);
    @media screen and (min-width: $tab) {
    padding-left: 25px;
    }
    @media screen and (min-width: 1150px) {
        max-width: 1241px;
        width: 100%;
    }
    @media screen and (min-width: 1281px) {
        // max-width: 1202px;
        // padding-inline: 25px;
        margin-right: auto;
    }
}

.works__article-wrap {
    margin-top: rem(20);
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
    padding-bottom: 20px;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: 18px;
        max-width: 1650px;
    }
}
/*スクロールバー非表示（Chrome・Safari）*/
.works__article-wrap::-webkit-scrollbar {
    display: none;
}

.works__card {
    background-image: url("../images/top/works-content-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-right: 26px;
    width: 253px;
    position: relative;
}

.works__content {
    padding: 80px 0 54px;
}

.works__category {
    position: absolute;
    top: 6%;
    left: 3%;
    background: #6e08e6;
    padding-inline: 20px;
    z-index: 10;
}

.works__article-img {
    max-width: 173px;
    width: 100%;
    margin-inline: auto;
}

.works__article-img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 173 / 250;
}

//worksホバー画像拡大
.works__content {
    overflow: hidden;
}
.works__article-img {
    transition: transform 0.5s ease;
}

.works__link:hover .works__article1-img,
.works__link:hover .works__article2-img,
.works__link:hover .works__article3-img,
.works__link:hover .works__article4-img,
.works__link:hover .works__article5-img {
    transform: scale(1.1);
}
