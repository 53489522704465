@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//編集力
.powerOfEditing__section-title-wrap {
    @media screen and (min-width: 1000px) {
        display: flex;
        flex-direction: column;
    }
}

.powerOfEditing__section-title {
    max-width: rem(330);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 781px;
    }
}

.powerOfEditing__section-text-wrap {
    margin-top: rem(34);
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tab) {
        margin-top: rem(13);
    }
    @media screen and (min-width: 1240px) {
        flex-direction: row;
    }
}

.powerOfEditing__section-text {
    font-weight: 900;
    font-size: rem(28);
    line-height: calc(38 / 30);
    @media screen and (min-width: $tab) {
        font-size: min(2.3437vw, 30px);
    }
}

//一覧ボタン
.powerOfEditing__button {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
        text-align: right;
    }
    @media screen and (min-width: 1240px) {
        margin-left: -170px;
        text-align: left;
        margin-top: rem(45);
    }
}

.powerOfEditing__button-text {
    font-family: $roboto;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    padding-right: 9px;
    display: inline-block;
    }


.powerOfEditing__button__arrow {
    border: 1px solid $txt-color;
        width: 19px;
        height: 19px;
}

.powerOfEditing__button__arrow.button__arrow.arrow::before {
    left: 4px;
    width: 10px;
}

.powerOfEditing__button__arrow.button__arrow.arrow::after {
    left: 10px;
    width: 3px;
    height: 3px;
}

//ホバー矢印
.powerOfEditing__button:hover .button__arrow {
    transform: translateX(8px);
}

.powerOfEditing__button__arrow.button__arrow.arrow::before {
    background: $txt-color;
}

.powerOfEditing__button__arrow.button__arrow.arrow::after {
    border-top: 1px solid $txt-color;
    border-right: 1px solid $txt-color;
}

.powerOfEditing__icon-link {
    @media screen and (min-width: 1150px) {
        order: 2;
        margin-left: auto;
    }
}

.powerOfEditing__inner {
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;
}

/*スクロールバー非表示（Chrome・Safari）*/
.powerOfEditing__inner::-webkit-scrollbar {
    display: none;
}

.powerOfEditing__article-wrap {
    margin-top: rem(31);
    display: flex;
    flex-wrap: nowrap;
    width: 1192px;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: 40px;
        width: calc(365px * 6 + 28px * 4);
        margin-inline: auto;
        padding-inline: 25px;
        row-gap: 40px;
    }
    @media screen and (min-width: 1200px) {
        flex-wrap: wrap;
        width: 1230px;
    }
}

.powerOfEditing__article {
    position: relative;
    margin-right: 28px;
    width: 309px;
    @media screen and (min-width: $tab) {
        width: 365px;
    }
}

.powerOfEditing__article:nth-last-child(1) {
    margin-right: 0;
}

.powerOfEditing__link::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 25%;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
    border-radius: 0 0 20px 20px;
}

.powerOfEditing__img {
    width: 309px;
    height: 100%;
    @media screen and (min-width: $tab){
        width: 365px;
    }
}

.powerOfEditing__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 309 / 408;
    @media screen and (min-width: $tab){
        aspect-ratio: 365 / 482;
    }
}

.powerOfEditing__category {
    position: absolute;
    top: 6%;
    left: 3%;
    background: #ff962c;
    padding-inline: 20px;
    z-index: 10;
}

.powerOfEditing__title {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 0 27px 16px 18px;
    font-size: 20px;
    line-height: calc(25 / 20);
    color: $white;
}

//powerOfEditingホバー画像拡大
.powerOfEditing__article {
    overflow: hidden;
    border-radius: 20px;
}
.powerOfEditing__img img {
    transition: transform 0.5s ease;
}

.powerOfEditing__link:hover .powerOfEditing__img1 img,
.powerOfEditing__link:hover .powerOfEditing__img2 img,
.powerOfEditing__link:hover .powerOfEditing__img3 img {
    transform: scale(1.1);
}


