@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//page-vision

.page-vision__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(25);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 100%;
        width: 1202px;
        padding-inline: 25px;
        overflow-y: hidden;
        overflow-x: auto;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
}
/*スクロールバー非表示（Chrome・Safari）*/
.page-vision__inner::-webkit-scrollbar {
    display: none;
}

.page-vision__contents {
    @media screen and (min-width: $tab) {
        display: inline-block;
        align-items: center;
    }
}

.page-vision__section-title img {
    max-width: rem(168);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 251px;
    }
}

.page-vision__title-img {
    margin-top: rem(70);
    display: flex;
    justify-content: center;
    @media screen and (min-width: $tab) {
        margin-top: rem(109);
    }
}

.page-vision__title-img img {
    max-width: rem(640);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 626px;
    }
}

.page-vision__text {
    margin-top: rem(37);
    font-weight: 900;
    font-size: rem(24);
    line-height: calc(30 / 24);
    text-align: center;
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
        font-size: 53px;
        line-height: calc(65 / 53);
        width: 689px;
        margin-inline: auto;
    }
}
