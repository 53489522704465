@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-recruit {
    margin-top: rem(60);
    margin-bottom: rem(81);
    @media screen and (min-width: $tab) {
        margin-top: 81px;
        margin-bottom: 150px;
    }
}
