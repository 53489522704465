@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-page-news  {
    margin-top: rem(32);
    @media screen and (min-width: $tab) {
        margin-top: rem(44);
    }
}
